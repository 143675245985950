<div class="container u-marginTxxl u-flex u-marginBm">
  <div class="align-right">Cohorts</div>
</div>
<br />

<div class="container u-paddingBxxl">
  <mat-accordion>
    @for (cohort of cohorts$ | async; track cohort._id) {
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <a [routerLink]="['/cohorts', cohort._id]">{{ cohort.name }}</a>
          </mat-panel-title>
          <mat-panel-description>
            {{ cohort.description }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="panel-content">
          <ul style="list-style: none; margin: 0; padding: 0 0 0 15px">
            @for (user of cohort.users; track user.id || user.email?.text) {
              <li>
                @if (user.id) {
                  {{ user.id }}
                } @else {
                  <span style="font-weight: bold">{{
                    user.email?.comparator
                  }}</span>
                  {{ user.email?.text }}
                }
              </li>
            }
          </ul>
        </div>
      </mat-expansion-panel>
    }
  </mat-accordion>

  <br /><br />

  <div class="u-width--full u-marginRxs">
    <mat-label class="o-form-label">New Cohort Name</mat-label>
    <mat-form-field style="width: 100%">
      <input
        type="text"
        matInput
        placeholder="Cohort Name"
        autocomplete="off"
        #nameInput
      />
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      (click)="createCohort(nameInput.value)"
    >
      Create Cohort
    </button>
  </div>
</div>
