@if ((loading$ | async) === false) {
  <form [formGroup]="agencyForm">
    <div class="agency-header-container container container--small">
      <div class="agency-header">
        <h2>Agency</h2>
        <a mat-icon-button [href]="agencyLink" target="_blank">
          <app-icon name="link-simple-horizontal" />
        </a>
      </div>
    </div>

    <div class="container container--small">
      <div class="name-hubspot-inputs">
        <div>
          <mat-label class="form-label">Name</mat-label>
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="name"
              placeholder="Name"
              autocomplete="off"
              [skElementId]="e2e.AgencyInputFieldName"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-label class="form-label">Hubspot Company Id *</mat-label>
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="hubspotCompanyId"
              placeholder="ID"
              autocomplete="off"
              [skElementId]="e2e.AgencyInputFieldHubspotCompanyId"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="active-checkbox-container">
        <div>
          <mat-checkbox
            formControlName="active"
            [skElementId]="e2e.AgencyCheckboxStatus"
            >Active
          </mat-checkbox>
          @if (
            (agency$ | async)?.active === false &&
            (agency$ | async)?.inactiveSince
          ) {
            <div>
              (Inactive Since: {{ (agency$ | async)?.inactiveSince | date }})
            </div>
          }
        </div>
      </div>

      <div class="customer-settings-container">
        <div>
          <mat-label class="form-label">Customer Type</mat-label>
          <mat-form-field [skElementId]="e2e.AgencyDropdownCustomerType">
            <mat-select
              formControlName="customerType"
              [skElementId]="e2e.AgencyDropdownCustomerTypeList"
            >
              @for (
                customer of customerTypeOptions;
                track customer;
                let i = $index
              ) {
                <mat-option
                  value="{{ customer }}"
                  [skElementId]="e2e.AgencyDropdownCustomerTypeItem"
                  [skElementIdIndex]="i"
                >
                  {{ customer }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>

        <div formGroupName="featureSettings">
          <mat-label class="form-label"> Users Limit</mat-label>
          <mat-form-field
            formGroupName="maxUsersAmount"
            [skElementId]="e2e.AgencyInputFieldUsersLimit"
          >
            <input type="number" matInput formControlName="value" min="0" />
          </mat-form-field>
        </div>

        <div formGroupName="featureSettings">
          <mat-label class="form-label">Customer Admin</mat-label>
          <div formGroupName="customerAdmin">
            <mat-checkbox
              formControlName="value"
              [skElementId]="e2e.AgencyCheckboxCustomerAdmin"
            >
              Enable
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>

    <mat-sidenav-container>
      <mat-sidenav mode="side" opened>
        <mat-action-list>
          @if (mode() === modes.Edit) {
            <button
              mat-list-item
              [activated]="activeTab() === 'clients'"
              (click)="activeTab.set('clients')"
              [skElementId]="e2e.AgencyMenuItemClients"
            >
              <span class="unsaved-change">{{
                hasUnsavedClientChanges ? '!' : ''
              }}</span>
              Clients ({{ currentClients().length }})
            </button>
          }

          <button
            mat-list-item
            [activated]="activeTab() === 'premium-features'"
            (click)="activeTab.set('premium-features')"
            [skElementId]="e2e.AgencyMenuItemPremiumFeatures"
          >
            <span class="unsaved-change">{{
              hasUnsavedPremiumChanges ? '!' : ''
            }}</span>
            Premium Features
          </button>

          <button
            mat-list-item
            [activated]="activeTab() === 'free-trial'"
            (click)="activeTab.set('free-trial')"
          >
            <span class="unsaved-change">{{
              hasUnsavedFreeTrialChanges ? '!' : ''
            }}</span>
            Free Trial Settings
          </button>

          @if (mode() === modes.Edit) {
            <button
              mat-list-item
              [activated]="activeTab() === 'fonts'"
              (click)="activeTab.set('fonts')"
              [skElementId]="e2e.AgencyMenuItemFonts"
            >
              <span class="unsaved-change">{{
                hasUnsavedFontChanges ? '!' : ''
              }}</span>
              Fonts ({{ (fonts$ | async)?.length }})
            </button>
          }
          @if (mode() === modes.Edit && currentClients()) {
            <button
              mat-list-item
              [activated]="activeTab() === 'users'"
              (click)="activeTab.set('users')"
              [skElementId]="e2e.AgencyMenuItemUser"
            >
              <span class="unsaved-change">{{
                hasUnsavedFontChanges ? '!' : ''
              }}</span>
              Users ({{ (usersDataSource.users$ | async)?.users?.length }})
            </button>
          }
          @if (mode() === modes.Edit && canEditLanguageInstruction()) {
            <button
              mat-list-item
              [activated]="activeTab() === 'script-creators'"
              (click)="activeTab.set('script-creators')"
            >
              Script Creators
            </button>
          }
          @if (mode() === modes.Edit) {
            <button
              mat-list-item
              [activated]="activeTab() === 'custom-talking-head-slide'"
              (click)="activeTab.set('custom-talking-head-slide')"
            >
              Custom Talking Head Slide
            </button>
          }
          @if (mode() === modes.Edit && currentClients() && canAdminFeeds()) {
            <button
              mat-list-item
              [activated]="activeTab() === 'feeds'"
              (click)="activeTab.set('feeds')"
            >
              Feeds
            </button>
          }
        </mat-action-list>
      </mat-sidenav>
      <mat-sidenav-content style="padding: 24px">
        @switch (activeTab()) {
          @case ('clients') {
            <app-agency-clients
              [currentClients]="currentClients()"
              [agencyHubspotId]="agencyForm.value.hubspotCompanyId ?? ''"
              [hasUnsavedClientChanges]="hasUnsavedClientChanges"
              (hasUnsavedClientChangesChange)="hasUnsavedClientChanges = $event"
              (clientsToCreate)="clientsToCreate = $event"
              (clientsToUpdate)="clientsToUpdate = $event"
              (clientsToDelete)="clientsToDelete = $event"
            ></app-agency-clients>
          }
          @case ('premium-features') {
            <app-premium-features-form
              [form]="agencyFeatureSettingsFormGroup"
            ></app-premium-features-form>
          }
          @case ('free-trial') {
            <app-free-trial-form [form]="agencyForm"></app-free-trial-form>
          }
          @case ('fonts') {
            <app-font-calc-list
              [agencyId]="(agencyId$ | async)!"
              [fonts]="(fonts$ | async)!"
              [clients]="currentClients()"
              (hasUnsavedFontChanges)="fontChangesHandler($event)"
            ></app-font-calc-list>
          }
          @case ('users') {
            <app-user-list [usersDataSource]="usersDataSource"></app-user-list>
          }
          @case ('script-creators') {
            <mat-form-field formGroupName="settings">
              <mat-label
                >Language Instruction (Do not use without approval from Product
                Department)
              </mat-label>
              <textarea
                matInput
                formControlName="scriptCreatorLanguageInstruction"
              ></textarea>
            </mat-form-field>
          }
          @case ('custom-talking-head-slide') {
            <mat-form-field formGroupName="settings">
              <mat-label>Custom slide definition type name</mat-label>
              <input
                matInput
                type="text"
                formControlName="customTalkingHeadSlide"
                placeholder="talking_head_storytelling_company"
              />
            </mat-form-field>
          }
          @case ('feeds') {
            <app-admin-feeds
              [agencyId]="(agencyId$ | async)!"
              [clients]="currentClients()"
            ></app-admin-feeds>
          }
        }
      </mat-sidenav-content>
    </mat-sidenav-container>

    @switch (mode()) {
      @case (modes.Add) {
        <div class="create-agency-container container container--small">
          <button
            [disabled]="formLoading || agencyForm.invalid"
            mat-flat-button
            data-test-id="create-agency-button"
            color="primary"
            (click)="createAgency()"
            [skElementId]="e2e.AgencyButtonCreateAgency"
          >
            Create Agency
          </button>
        </div>
      }
      @case (modes.Edit) {
        <div class="container container--small warning-unsaved-changes">
          @if (
            hasUnsavedClientChanges ||
            hasUnsavedFontChanges ||
            hasUnsavedFormChanges
          ) {
            <span>Unsaved Changes!</span>
          }
        </div>
        <div class="buttons-container">
          <button
            [disabled]="formLoading || agencyForm.invalid || hasExpiredTrial"
            mat-flat-button
            data-test-id="update-agency-button"
            color="primary"
            (click)="updateAgency()"
            [skElementId]="e2e.AgencyButtonUpdateAgency"
          >
            Update Agency
          </button>
          <button
            mat-flat-button
            [disabled]="formLoading"
            data-test-id="delete-agency-button"
            color="warn"
            (click)="openDeleteDialog()"
            [skElementId]="e2e.AgencyButtonDeleteAgency"
          >
            Delete Agency
          </button>
          <button
            mat-stroked-button
            [disabled]="formLoading || !hasExpiredTrial"
            data-test-id="restart-agency-button"
            color="primary"
            (click)="openRestartTrialDialog()"
            [skElementId]="e2e.AgencyButtonRestartTrial"
          >
            Reactivate Trial Agency
          </button>
        </div>
      }
    }
  </form>
}
<app-error [observable$]="agency$"></app-error>
