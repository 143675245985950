import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MATERIAL_MODULES } from '@shared/material-design/material-modules';
import { UserCacheService } from '@views/feature-flags/user-cache.service';
import { lastValueFrom } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
} from 'rxjs/operators';

@Component({
  selector: 'app-user-autocomplete-selectbox',
  standalone: true,
  imports: [MATERIAL_MODULES, ReactiveFormsModule, CommonModule],
  templateUrl: './user-autocomplete-selectbox.component.html',
  styleUrl: './user-autocomplete-selectbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UserAutocompleteSelectboxComponent,
      multi: true,
    },
  ],
})
export class UserAutocompleteSelectboxComponent
  implements ControlValueAccessor
{
  searchControl = new FormControl('');
  filteredUsers$ = this.searchControl.valueChanges.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    filter((value): value is string => !!value && value.length > 2),
    switchMap((value) =>
      // if object id, to avoid extra request after user is selected
      value.match(/^[0-9a-f-]{24,36}$/)
        ? this.userCacheService
            .getUser(value)
            .pipe(map((user) => (user ? [user] : [])))
        : this.userCacheService
            .getUsers({ search: value })
            .pipe(map((value) => value.users))
    )
  );

  private onChange = (_value: string) => {};
  private onTouched = () => {};

  constructor(
    private userCacheService: UserCacheService,
    private cdr: ChangeDetectorRef
  ) {}

  async writeValue(userId: string) {
    if (userId) {
      const user = await lastValueFrom(this.userCacheService.getUser(userId));
      this.searchControl.setValue(user._id, {
        emitEvent: false,
      });
    } else {
      this.searchControl.setValue('', {
        emitEvent: false,
      });
    }
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.searchControl.disable();
    } else {
      this.searchControl.enable();
    }
  }

  onUserSelected(event: MatAutocompleteSelectedEvent) {
    this.searchControl.setValue(event.option.value, { emitEvent: false });
    this.onChange(event.option.value);
    this.onTouched();
  }

  getUserInfo(userId: string): string {
    const user = this.userCacheService.getUserFromCache(userId);
    return user ? `${user.firstName} ${user.lastName} (${user.email})` : '';
  }
}
