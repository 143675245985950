<form
  [formGroup]="addFeedForm"
  method="POST"
  (submit)="submitAddFeed(successDialog, errorDialog)"
  #addNgForm="ngForm"
>
  <mat-form-field>
    <mat-label>Feed Name</mat-label>
    <input matInput formControlName="name" type="text" />
    @if (
      addFeedForm.controls.name.invalid &&
      (addFeedForm.controls.name.touched || addFeedForm.controls.name.dirty)
    ) {
      <mat-error>{{ addFeedForm.controls.name.errors | json }}</mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label>Feed XML URL</mat-label>
    <input
      matInput
      formControlName="feedUrl"
      type="text"
      placeholder="https://..."
    />
    @if (
      addFeedForm.controls.feedUrl.invalid &&
      (addFeedForm.controls.feedUrl.touched ||
        addFeedForm.controls.feedUrl.dirty)
    ) {
      <mat-error>{{ addFeedForm.controls.feedUrl.errors | json }}</mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label>Client</mat-label>

    <mat-select formControlName="clientId">
      @for (client of clients(); track client._id) {
        <mat-option [value]="client._id">{{ client.name }}</mat-option>
      }
    </mat-select>

    @if (
      addFeedForm.controls.clientId.invalid &&
      (addFeedForm.controls.clientId.touched ||
        addFeedForm.controls.clientId.dirty)
    ) {
      <mat-error>{{ addFeedForm.controls.clientId.errors | json }}</mat-error>
    }
  </mat-form-field>
  @if (themes().length > 0) {
    <mat-form-field>
      <mat-label>Theme</mat-label>
      <mat-select formControlName="themeId">
        @for (theme of themes(); track theme.id) {
          <mat-option [value]="theme.id">{{ theme.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (
      addFeedForm.controls.themeId.invalid &&
      (addFeedForm.controls.themeId.touched ||
        addFeedForm.controls.themeId.dirty)
    ) {
      <mat-error>{{ addFeedForm.controls.themeId.errors | json }}</mat-error>
    }
  } @else {
    <mat-form-field>
      <mat-label>Theme ID</mat-label>
      <input
        matInput
        formControlName="themeId"
        type="text"
        placeholder="e.g. 60dc5c746f174f004c415eee"
      />
    </mat-form-field>
    @if (
      addFeedForm.controls.themeId.invalid &&
      (addFeedForm.controls.themeId.touched ||
        addFeedForm.controls.themeId.dirty)
    ) {
      <mat-error>{{ addFeedForm.controls.themeId.errors | json }}</mat-error>
    }
  }

  @if (campaigns().length > 0) {
    <mat-form-field>
      <mat-label>Campaign</mat-label>
      <mat-select formControlName="campaignId">
        @for (campaign of campaigns(); track campaign.id) {
          <mat-option [value]="campaign.id" style="line-height: 1">
            <strong>{{ campaign.name }}</strong>
            {{ campaign.isCustom ? ' (custom)' : '' }}
            {{
              campaign.campaignCreators.length > 1
                ? '(' + campaign.campaignCreators.length + ' creators)'
                : ''
            }}<br />
            <small style="white-space: nowrap">{{
              campaign.description
            }}</small>
          </mat-option>
        }
      </mat-select>
      @if (
        addFeedForm.controls.campaignId.invalid &&
        (addFeedForm.controls.campaignId.touched ||
          addFeedForm.controls.campaignId.dirty)
      ) {
        <mat-error>{{
          addFeedForm.controls.campaignId.errors | json
        }}</mat-error>
      }
    </mat-form-field>
  } @else {
    <mat-form-field>
      <mat-label>Campaign ID</mat-label>
      <input
        matInput
        formControlName="campaignId"
        type="text"
        placeholder="e.g. d2fc8ca6-5cb2-4309-80a3-256401386e09"
      />
      @if (
        addFeedForm.controls.campaignId.invalid &&
        (addFeedForm.controls.campaignId.touched ||
          addFeedForm.controls.campaignId.dirty)
      ) {
        <mat-error>{{
          addFeedForm.controls.campaignId.errors | json
        }}</mat-error>
      }
    </mat-form-field>
  }

  <mat-form-field>
    <mat-label>Notification E-mails</mat-label>
    <textarea
      matInput
      formControlName="notificationEmail"
      type="text"
      placeholder="e.g. eam@storykit.io, person@customer.com"
    ></textarea>

    <mat-hint>Separate multiple e-mails with a comma</mat-hint>
    @if (addFeedForm.controls.notificationEmail.invalid) {
      <mat-error>{{
        addFeedForm.controls.notificationEmail.errors | json
      }}</mat-error>
    }
  </mat-form-field>

  @if (assetLibraries().length > 0) {
    <mat-form-field>
      <mat-label>Asset Library</mat-label>
      <input
        type="text"
        matInput
        formControlName="assetLibraryId"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        @for (assetLibrary of assetLibraries(); track assetLibrary.id) {
          <mat-option [value]="assetLibrary.id"
            >{{ assetLibrary.name }} ({{ assetLibrary.id }})</mat-option
          >
        }
      </mat-autocomplete>
      @if (
        addFeedForm.controls.assetLibraryId.invalid &&
        (addFeedForm.controls.assetLibraryId.touched ||
          addFeedForm.controls.assetLibraryId.dirty)
      ) {
        <mat-error>{{
          addFeedForm.controls.assetLibraryId.errors | json
        }}</mat-error>
      }
    </mat-form-field>
  } @else {
    <mat-form-field>
      <mat-label>Asset Library ID</mat-label>
      <input
        matInput
        formControlName="assetLibraryId"
        type="text"
        placeholder="e.g. 60dc5c746f174f004c415eee"
      />
      @if (addFeedForm.controls.assetLibraryId.invalid) {
        <mat-error>{{
          addFeedForm.controls.assetLibraryId.errors | json
        }}</mat-error>
      }
    </mat-form-field>
  }

  <div class="languages">
    <mat-form-field>
      <mat-label>Source language</mat-label>
      <mat-select formControlName="sourceLanguage">
        @for (language of languages(); track language) {
          <mat-option [value]="language">{{ language | titlecase }}</mat-option>
        }
      </mat-select>
      <mat-hint>Fallback when no source language is set in creator</mat-hint>
      @if (addFeedForm.controls.sourceLanguage.invalid) {
        <mat-error>{{
          addFeedForm.controls.sourceLanguage.errors | json
        }}</mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Output language</mat-label>

      <mat-select formControlName="outputLanguage">
        @for (language of languages(); track language) {
          <mat-option [value]="language">{{ language | titlecase }}</mat-option>
        }
      </mat-select>
      <mat-hint>Fallback when no output language is set in creator</mat-hint>
      @if (addFeedForm.controls.sourceLanguage.invalid) {
        <mat-error>{{
          addFeedForm.controls.sourceLanguage.errors | json
        }}</mat-error>
      }
    </mat-form-field>
  </div>
  <div class="submit">
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="loading()"
      matTooltip="Might take up to 30 seconds"
    >
      Create & Process feed
    </button>
    @if (loading()) {
      <mat-progress-bar mode="buffer"></mat-progress-bar>
    }
  </div>

  <ng-template #errorDialog let-data>
    <h2 mat-dialog-title>Failed to create feed!</h2>
    <mat-dialog-content>
      <pre>{{ data.error | json }}</pre>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="true">Close</button>
    </mat-dialog-actions>
  </ng-template>

  <ng-template #successDialog let-data>
    <h2 mat-dialog-title>Feed created!</h2>
    <mat-dialog-content>
      <p>
        Feed has been created and is now processing.<br />
        <a href="{{ data.url }}" target="_blank">Open Feed in Create</a>
      </p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="true">Close</button>
    </mat-dialog-actions>
  </ng-template>
</form>
