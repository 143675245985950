import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { CwsService } from '@core/services/cws.service';
import { userRoleLabels, userRoles } from '@models/user';

import { ElementId } from '@storykit/constants';

import { UserListDataSource } from './user-list.dataSource';

@Component({
  selector: 'app-user-list-view',
  templateUrl: './user-list.component.html',
  styleUrls: [
    '../../partials/table/table.scss',
    './user-list-table.scss',
    '../../../../styles/settings-form.scss',
  ],
})
export class UserListViewComponent implements OnInit {
  userRoles = userRoles;
  userRoleLabels = userRoleLabels;
  usersDataSource = new UserListDataSource(this.cwsService);
  includeDeleted = false;

  elementId = ElementId;

  constructor(
    private cwsService: CwsService,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.breadcrumbsService.set([{ label: 'Users', link: '/users' }]);
  }

  setIncludeDeleted(value: boolean) {
    this.includeDeleted = value;
    this.usersDataSource.includeDeleted(value);
  }

  ngOnInit(): void {
    this.setInitialRoute();

    this.usersDataSource.filters$.subscribe((queryParams) => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    });
  }

  clearSearch() {
    this.usersDataSource.updateSearch({ search: '' });
  }

  private setInitialRoute() {
    const {
      role = '',
      search = '',
      pageSize,
      pageIndex,
    } = this.route.snapshot.queryParams;
    this.usersDataSource.updateRole(role);
    this.usersDataSource.updatePage({
      pageSize: Number(pageSize) || 25,
      pageIndex: Number(pageIndex) - 1 || 0,
    });
    this.usersDataSource.updateSearch({ search });
  }
}
