<div [skElementId]="elementId.Admin.ConfirmationModal">
  <h1 mat-dialog-title>
    {{ capitalize(data.action) }} {{ capitalize(data.subject) }}
  </h1>
  <div [skElementId]="elementId.Admin.ConfirmationModalText">
    @if (
      lowercase(data.subject) !== 'agency' &&
      lowercase(data.subject) !== 'clients'
    ) {
      <div mat-dialog-content>
        Are you sure you want to {{ lowercase(data.action) }} this
        {{ data.subject }}
        @if (data.name) {
          <span>: "{{ data.name }}"</span>
        }
        ?
      </div>
    }
    @if (
      lowercase(data.subject) === 'agency' &&
      lowercase(data.action) === 'delete'
    ) {
      <div mat-dialog-content>
        This {{ data.subject }} will be marked as inactive and be queued for
        deletion the next day.<br />
        Are you sure you want to continue?
      </div>
    }
    @if (
      lowercase(data.subject) === 'agency' &&
      lowercase(data.action) === 'reactivate'
    ) {
      <div mat-dialog-content>
        Reactivate this {{ data.subject }} with a new 14 day trial and unblock
        all users. <br />These changes can be modified after reactivation.<br /><br />
        Are you sure you want to continue?
      </div>
    }
    @if (
      lowercase(data.subject) === 'clients' &&
      lowercase(data.action) === 'delete'
    ) {
      <div mat-dialog-content>
        There are {{ data.amount }} {{ data.subject }} that will be deleted with
        this update. Are you sure you want to continue?
      </div>
    }
  </div>

  <div mat-dialog-actions>
    <button
      mat-flat-button
      [color]="lowercase(data.action) === 'reactivate' ? 'primary' : 'warn'"
      data-test-id="block-dialog-button"
      [mat-dialog-close]="true"
      [skElementId]="elementId.Admin.ConfirmationModalButtonDelete"
    >
      {{ capitalize(data.action) }}
    </button>
    <button
      mat-flat-button
      mat-dialog-close
      [skElementId]="elementId.Admin.ConfirmationModalButtonCancel"
    >
      Cancel
    </button>
  </div>
</div>
