import {
  ChangeDetectionStrategy,
  Component,
  TrackByFunction,
  effect,
  inject,
  input,
  model,
  output,
  signal,
  viewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormRecord,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatButton,
  MatIconAnchor,
  MatIconButton,
} from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow,
  MatHeaderRowDef,
  MatRow,
  MatRowDef,
  MatTable,
} from '@angular/material/table';
import { ClientService } from '@core/services/client.service';
import { environment } from '@environment';
import { Client } from '@models/client';
import { ClientTableEntry } from '@views/agency/agency/agency.types';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { lastValueFrom } from 'rxjs';

import { ElementId, ElementIdType } from '@storykit/constants';
import {
  AssertionError,
  ElementIdDirective,
  IconComponent,
} from '@storykit/ui-components';

import IClient = kit.IClient;

@Component({
  selector: 'app-agency-clients',
  standalone: true,
  imports: [
    ElementIdDirective,

    ReactiveFormsModule,

    MatTable,
    MatHeaderCell,
    MatColumnDef,
    MatCell,
    MatHeaderCellDef,
    MatCellDef,
    MatFormField,
    MatInput,
    MatIconButton,
    IconComponent,
    MatIconAnchor,
    MatHeaderRow,
    MatHeaderRowDef,
    MatRow,
    MatRowDef,
    MatLabel,
    MatButton,
  ],
  templateUrl: './agency-clients.component.html',
  styleUrls: [
    './agency-clients.component.scss',
    '../../../../../styles/settings-form.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyClientsComponent {
  constructor() {
    effect(
      () => {
        const clients = this.currentClients();

        this.clientDataSource.set(
          clients.map(
            (client): ClientTableEntry => ({
              client,
              deleted: false,
              new: false,
              updated: false,
            })
          )
        );
        this.hubspotControls = new FormRecord<FormControl<string>>({});
        clients.forEach((client, i) => {
          this.hubspotControls.addControl(
            'hubspotCompanyId' + i,
            new FormControl(client.hubspotCompanyId, {
              validators: [Validators.required],
              nonNullable: true,
            })
          );
        });

        this.clientTable()?.renderRows();
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      const clientDataSource = this.clientDataSource();

      this.clientsToDelete.emit(
        clientDataSource
          .filter((entry) => entry.deleted)
          .map((entry) => entry.client._id)
      );

      this.clientsToCreate.emit(
        clientDataSource
          .filter((entry) => entry.new)
          .map((entry) => entry.client)
      );

      this.clientsToUpdate.emit(
        clientDataSource
          .filter((entry) => entry.updated)
          .map((entry) => entry.client)
      );
    });
  }

  readonly createUrl = environment.create.link;
  readonly trackClientsByFn: TrackByFunction<ClientTableEntry> = (
    index,
    item
  ) => item.client._id;

  currentClients = input.required<Required<IClient>[]>();
  agencyHubspotId = input.required<string>();

  clientDataSource = signal<ClientTableEntry[]>([]);

  clientsToCreate = output<IClient[]>();
  clientsToUpdate = output<Required<IClient>[]>();
  clientsToDelete = output<string[]>();

  hasUnsavedClientChanges = model.required<boolean>();

  clientTable = viewChild(MatTable<ClientTableEntry>);

  private dialog: MatDialog = inject(MatDialog);
  private formBuilder: FormBuilder = inject(FormBuilder);
  private clientService: ClientService = inject(ClientService);

  e2e: { [_key in keyof typeof ElementId.Admin]: ElementIdType } =
    ElementId.Admin;

  clientTableColumns = ['name', 'hubspotId', 'delete', 'link'];
  hubspotControls = new FormRecord<FormControl<string>>({});
  clientForm: FormGroup<{ name: FormControl<string | null> }> =
    this.formBuilder.group({
      name: ['', Validators.required],
    });

  saveClient(formName: string, index: number) {
    this.clientDataSource.update(
      (entries: ClientTableEntry[]): ClientTableEntry[] =>
        entries.map((entry, i) => {
          if (index === i) {
            return {
              ...entry,
              client: {
                ...entry.client,
                hubspotCompanyId: this.hubspotControls.get(formName)?.value,
              },
              updated: true,
            };
          } else {
            return entry;
          }
        })
    );

    this.hasUnsavedClientChanges.set(true);
  }

  async deleteClient(
    clientTableEntry: ClientTableEntry,
    index: number
  ): Promise<void> {
    const dialogRef = ConfirmDialogComponent.open(this.dialog, {
      action: 'Delete',
      subject: 'client',
      name: clientTableEntry.client.name,
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());

    if (!confirmed) return;

    // Remove yet to be created clients from the data source
    if (clientTableEntry.new) {
      this.clientDataSource.update((clients) => {
        const newClients = [...clients];
        newClients.splice(index, 1);
        return newClients;
      });
      this.clientTable()?.renderRows();
      // true, if a new client has been created or an existing client has been updated/deleted
      this.hasUnsavedClientChanges.set(
        this.clientDataSource().some(
          (client) => client.new || client.updated || client.deleted
        )
      );
      return;
    }

    // Show unsaved changes if client already in the data source is deleted before updating agency
    this.clientDataSource.update(
      (entries: ClientTableEntry[]): ClientTableEntry[] =>
        entries.map((entry) => {
          if (entry.client._id === clientTableEntry.client._id) {
            return {
              ...entry,
              deleted: true,
            };
          } else {
            return entry;
          }
        })
    );
    this.hasUnsavedClientChanges.set(true);
  }

  generateClientLinkCreate(clientTableEntry: ClientTableEntry) {
    const clientId = clientTableEntry.client._id;
    return `${this.createUrl}/clients/${clientId}`;
  }

  // Reset client input field error if left without a value

  clientInputBlur(): void {
    if (!this.clientForm.value?.name) {
      this.clientForm.markAsUntouched();
    }
  }

  addClient() {
    const defaultHubspotValue = this.agencyHubspotId();
    this.hubspotControls.addControl(
      'hubspotCompanyId' + this.clientDataSource().length,
      new FormControl<string>(defaultHubspotValue, {
        validators: Validators.required,
        nonNullable: true,
      })
    );
    this.clientDataSource.update((clients) => {
      const name = this.clientForm.value.name;
      if (!name) {
        throw new AssertionError('Client name is required');
      }
      const newEntry: ClientTableEntry = {
        client: {
          name: name,
          hubspotCompanyId: defaultHubspotValue,
        } as Client,
        deleted: false,
        new: true,
        updated: false,
      };
      return [...clients, newEntry];
    });
    this.clientForm.reset();
    this.clientTable()?.renderRows();
    this.hasUnsavedClientChanges.set(true);
  }
}
