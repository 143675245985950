import { USER_ROLE } from '@storykit/constants';
import { User as IUser } from '@storykit/typings/src/cws';

export type User = IUser;
export type UserRole = kit.TUserRole;
export type Access = kit.IUserAccess;
export type AccessTypes = kit.TAccessType;
export type Languages = (typeof languages)[number];
export type MutableUser = Omit<
  User,
  '_id' | 'registered' | 'updated' | 'blocked'
>;

export const languages = ['eng', 'swe'] as const;
export const languageLabels = {
  eng: 'English',
  swe: 'Swedish',
};

export const userRoles = [
  USER_ROLE.CUSTOMER,
  USER_ROLE.CUSTOMER_MANAGER,
  USER_ROLE.ADMINISTRATOR,
] as const;

export const userRoleLabels = {
  administrator: 'Storykit Administrator',
  customer_manager: 'Storykit Customer Manager',
  customer: 'Customer',
};

export const roles = ['native_administrator', 'native_editor'] as const;
export const roleLabels = {
  native_administrator: 'Administrator',
  native_editor: 'Editor',
};

export const accessTypes = ['client', 'agency'] as const;
export const accessTypeLabels = {
  client: 'Client',
  agency: 'Agency',
};

export const allowedAdminRoleEmailPatterns = ['@storykit.io', '@kit.se'];
