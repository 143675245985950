<div class="search-container">
  <div>
    <mat-label class="form-label">Search</mat-label>
    <mat-form-field>
      <input
        #search
        data-test-id="user-list-search"
        [value]="(usersDataSource.filters$ | async)?.search || ''"
        (input)="usersDataSource.updateSearch({ search: search.value })"
        type="text"
        matInput
        placeholder="Search"
        [skElementId]="elementId.Admin.UserSearchField"
      />
      @if (search.value) {
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearch()"
        >
          <app-icon name="x" />
        </button>
      }
    </mat-form-field>
  </div>

  <div>
    <mat-label class="form-label">Filter role</mat-label>
    <mat-form-field>
      <mat-select
        [value]="(usersDataSource.filters$ | async)?.role"
        (selectionChange)="usersDataSource.updateRole($event.value)"
      >
        <mat-option value="">All</mat-option>
        @for (role of userRoles; track role) {
          <mat-option value="{{ role }}">
            {{ userRoleLabels[role] }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-label class="form-label">Filter deleted</mat-label>
    <mat-checkbox
      [ngModel]="includeDeleted"
      (ngModelChange)="setIncludeDeleted($event)"
      [skElementId]="elementId.Admin.UserCheckboxDeleted"
      >Enable</mat-checkbox
    >
  </div>
  <div class="align-right">
    <button
      mat-raised-button
      color="primary"
      [matMenuTriggerFor]="menu"
      [skElementId]="elementId.Admin.ButtonUserActions"
    >
      User actions
    </button>
    <mat-menu #menu="matMenu">
      <div [skElementId]="elementId.Admin.UserDropdownMenu">
        <a
          [routerLink]="['/users/add']"
          class="create-user-button"
          mat-button
          [skElementId]="elementId.Admin.ButtonCreateUser"
        >
          <app-icon name="user" weight="fill" class="menu-button-icon" />

          <span>Create user</span>
        </a>
        <a
          [routerLink]="['/users/bulk/add']"
          class="create-user-button"
          mat-button
        >
          <app-icon name="users-three" weight="fill" class="menu-button-icon" />

          <span>Bulk create users</span>
        </a>
      </div>
    </mat-menu>
  </div>
</div>

<div class="user-list-container">
  <app-user-list [usersDataSource]="usersDataSource"></app-user-list>
</div>
