@if ((loading$ | async) === false) {
  <form [formGroup]="slideDefinitionForm">
    <div class="slide-definition-header-container container container--small">
      <div class="slide-definition-header">
        <h1>Slide Definition</h1>
      </div>
    </div>
    <div class="container container--small">
      <div class="slide-defintion-section">
        <div>
          <mat-label class="form-label">Display name</mat-label>
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="displayName"
              placeholder="Display name"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-label class="form-label">Definition Group</mat-label>
          <mat-form-field>
            <mat-select formControlName="definitionGroup">
              @for (
                slideDefinitionGroup of slideDefinitionGroups;
                track slideDefinitionGroup
              ) {
                <mat-option value="{{ slideDefinitionGroup._id }}">
                  {{ slideDefinitionGroup.displayName }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="slide-defintion-section">
        <div>
          <mat-label class="form-label">Defintition type</mat-label>
          <mat-form-field>
            <mat-select
              formControlName="toggleDefinitionTypeSelect"
              #definitionTypeSelect
            >
              <mat-option value="New">New</mat-option>
              <mat-option value="Keep">Keep</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        @if (definitionTypeSelect.value === 'New') {
          <div>
            <mat-label class="form-label">New Definition Type</mat-label>
            <mat-form-field>
              <input
                type="text"
                matInput
                formControlName="definitionTypeName"
                placeholder="Name"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
        } @else if (definitionTypeSelect.value === 'Keep') {
          <div>
            <mat-label class="form-label">Choose existing</mat-label>
            <mat-form-field>
              <mat-select
                formControlName="definitionType"
                #definitionTypeSelect
              >
                @for (
                  slideDefinitionType of slideDefinitionTypes;
                  track slideDefinitionType
                ) {
                  <mat-option value="{{ slideDefinitionType._id }}">
                    {{ slideDefinitionType.name }}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        }
      </div>
      <div class="slide-defintion-section">
        <div>
          <mat-label class="form-label"
            >Preview Video (#t=1.00 in the end to regulate static
            image)</mat-label
          >
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="previewVideo"
              placeholder="link"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="slide-defintion-section">
        <div>
          <mat-label class="form-label">Zendesk link</mat-label>
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="infoLink"
              placeholder="link"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="container container--small">
      <h2>Timings</h2>
      <div class="slide-defintion-section" formGroupName="timings">
        <div>
          <mat-checkbox formControlName="editable"
            >Editable duration (when there's no variable control for setting
            duration)</mat-checkbox
          >
        </div>
      </div>
      <div class="slide-defintion-section" formGroupName="timings">
        <div>
          <mat-label class="form-label">Default duration (ms)</mat-label>
          <mat-form-field>
            <input
              type="number"
              matInput
              formControlName="duration"
              placeholder="5000"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
        <div>
          <mat-label class="form-label">Inherit from variable</mat-label>
          <mat-form-field>
            <input
              type="text"
              matInput
              formControlName="inheritsFrom"
              placeholder="duration"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="slide-defintion-section" formGroupName="garbo">
        <div formGroupName="preview">
          <mat-label class="form-label">At time</mat-label>
          <mat-slider
            thumbLabel
            [discrete]="true"
            [displayWith]="formatSliderLabel"
            min="0"
            max="1"
            step="0.01"
          >
            <input matSliderThumb formControlName="atTime" />
          </mat-slider>
        </div>
      </div>
      <div formGroupName="garbo">
        <h3>Clamp (Optional - milliseconds)</h3>
        <div formGroupName="preview">
          <div formGroupName="clamp" class="slide-defintion-section">
            <div>
              <mat-label class="form-label">Start</mat-label>
              <mat-form-field>
                <input
                  type="number"
                  matInput
                  formControlName="start"
                  placeholder="0"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-label class="form-label">End</mat-label>
              <mat-form-field>
                <input
                  type="number"
                  matInput
                  formControlName="end"
                  placeholder="7000"
                  autocomplete="off"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="slide-defintion-section">
        <div class="u-width--editor">
          <mat-label class="form-label">Variables</mat-label>
          <mat-form-field>
            <textarea
              matInput
              formControlName="variables"
              placeholder="{}"
              autocomplete="off"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="slide-defintion-section">
        <div>
          <mat-label class="form-label">Access Category</mat-label>
          <mat-form-field>
            <mat-select formControlName="accessCategory">
              @for (
                accessCategory of accessCategories | keyvalue;
                track accessCategory
              ) {
                <mat-option value="{{ accessCategory.value }}">
                  {{ accessCategory.value }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      @if (slideDefinitionForm.value.accessCategory === 'custom') {
        <div class="slide-def-access-form">
          <h3>Access</h3>
          <app-slide-definition-access-form
            [form]="slideDefinitionForm"
          ></app-slide-definition-access-form>
        </div>
      }
      <div class="slide-defintion-section">
        <div>
          <mat-label class="form-label">Layout</mat-label>
          <mat-form-field>
            <mat-select formControlName="layout">
              <mat-option> None </mat-option>
              @for (
                slideDefinitionLayout of slideDefinitionLayouts$ | async;
                track slideDefinitionLayout
              ) {
                <mat-option value="{{ slideDefinitionLayout._id }}">
                  {{ slideDefinitionLayout.displayName }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
    @if ((mode$ | async) === modes.Edit) {
      <div class="container container--small warning-unsaved-changes">
        @if (hasUnsavedFormChanges) {
          <span>Unsaved Changes!</span>
        }
      </div>
    }
    <div class="buttons-container">
      <button
        [disabled]="formLoading || slideDefinitionForm.invalid"
        mat-flat-button
        color="primary"
        (click)="createSlideDefinition()"
      >
        Create Slide Definition
      </button>
      @if ((mode$ | async) === modes.Edit) {
        <button
          [disabled]="formLoading || slideDefinitionForm.invalid"
          mat-flat-button
          color="primary"
          (click)="updateSlideDefinition()"
        >
          Update Slide Definitition
        </button>
        <button
          mat-flat-button
          [disabled]="formLoading"
          color="warn"
          (click)="openDeleteDialog()"
        >
          Delete Slide Definition
        </button>
      }
    </div>
  </form>
}
<app-error [observable$]="slideDefinition$"></app-error>
