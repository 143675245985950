import { Injectable, inject } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { environment } from '@environment';
import { Observable, map } from 'rxjs';

import {
  Campaign,
  GetCampaigns,
  GetWriterLanguages,
} from '@storykit/typings/src/coen';
import {
  GetClientAssetLibrary,
  GetClientVideoThemes,
} from '@storykit/typings/src/cws';
import {
  CreateFeed,
  DeleteFeed,
  FeedMetadata,
  FeedView,
  GetFeedsForAgency,
  Settings,
  UpdateFeed,
} from '@storykit/typings/src/cws/feed';

export interface VideoThemeForFeed {
  id: string;
  name: string;
}

export interface AssetLibraryForFeed {
  id: string;
  name: string;
}

@Injectable()
export class FeedsService {
  private api = inject(ApiService);

  getFeeds(agencyId: string): Observable<FeedMetadata[]> {
    return this.api
      .call<GetFeedsForAgency>({
        origin: environment.api.cws.endpoint,
        path: '/feed/agency/:agencyId',
        method: 'GET',
        params: { agencyId },
        query: null,
        body: {},
      })
      .pipe(map((res) => res?.body));
  }

  createFeed(clientId: string, feedSettings: Settings): Observable<FeedView> {
    return this.api
      .call<CreateFeed>({
        origin: environment.api.cws.endpoint,
        path: '/feed/client/:clientId',
        method: 'POST',
        params: { clientId },
        query: null,
        body: feedSettings,
      })
      .pipe(map((res) => res?.body));
  }

  editFeed(
    clientId: string,
    feedId: string,
    feedSettings: Settings
  ): Observable<FeedView> {
    return this.api
      .call<UpdateFeed>({
        origin: environment.api.cws.endpoint,
        path: '/feed/client/:clientId/feed/:feedId',
        method: 'PUT',
        params: { clientId, feedId },
        query: null,
        body: feedSettings,
      })
      .pipe(map((res) => res?.body));
  }

  deleteFeed(clientId: string, feedId: string) {
    return this.api
      .call<DeleteFeed>({
        origin: environment.api.cws.endpoint,
        path: '/feed/client/:clientId/feed/:feedId',
        method: 'DELETE',
        params: { clientId, feedId },
        query: null,
        body: {},
      })
      .pipe(map((res) => res?.body));
  }

  getThemes(clientId: string): Observable<VideoThemeForFeed[]> {
    return this.api
      .call<GetClientVideoThemes>({
        origin: environment.api.cws.endpoint,
        path: '/client/:clientId/videotheme',
        method: 'GET',
        params: {
          clientId,
        },
        query: {},
        body: {},
      })
      .pipe(
        map((res) => res?.body),
        map((themes: kit.vs.IVideoTheme[]) =>
          themes.map(
            (theme): VideoThemeForFeed => ({
              id: theme._id,
              name: theme.name,
            })
          )
        )
      );
  }

  getLanguages(): Observable<string[]> {
    return this.api
      .call<GetWriterLanguages>({
        origin: environment.api.coen.endpoint,
        path: '/writer/get-languages',
        method: 'POST',
        params: {},
        query: null,
        body: null,
      })
      .pipe(
        map((res) => res?.body.languages),
        map((languages) => languages.sort((a, b) => a.localeCompare(b)))
      );
  }

  getCampaigns(agencyId: string, clientId: string): Observable<Campaign[]> {
    return this.api
      .call<GetCampaigns>({
        origin: environment.api.coen.endpoint,
        path: '/campaign/get-campaigns',
        method: 'GET',
        params: {},
        query: {
          agencyId,
          clientId,
        },
        body: {},
      })
      .pipe(map((res) => res?.body));
  }

  getClientAssetLibraries(clientId: string): Observable<AssetLibraryForFeed[]> {
    return this.api
      .call<GetClientAssetLibrary>({
        origin: environment.api.cws.endpoint,
        path: `/asset-library/client`,
        method: 'GET',
        params: {},
        query: { orderBy: 'name', order: 'asc', client: clientId },
        body: {},
      })
      .pipe(map((res) => this.mapAssetLibrariesForFeed(res?.body)));
  }

  getAgencyAssetLibraries(agencyId: string): Observable<AssetLibraryForFeed[]> {
    return this.api
      .call<GetClientAssetLibrary>({
        origin: environment.api.cws.endpoint,
        path: `/asset-library/agency`,
        method: 'GET',
        params: {},
        query: { orderBy: 'name', order: 'asc', agency: agencyId },
        body: {},
      })
      .pipe(map((res) => this.mapAssetLibrariesForFeed(res?.body)));
  }

  private mapAssetLibrariesForFeed(result: {
    count: number;
    data: kit.IAssetLibrary[];
  }): AssetLibraryForFeed[] {
    return result.data
      .filter((assetLibrary) => assetLibrary._id)
      .map(
        (assetLibrary: kit.IAssetLibrary): AssetLibraryForFeed => ({
          id: assetLibrary._id,
          name: assetLibrary.name,
        })
      );
  }
}
