<app-global-progress></app-global-progress>
<div class="navbar">
  <a
    class="navbar-logo"
    [ngClass]="{ 'navbar-logo--disabled': !authenticated }"
    href="/"
  >
    <img src="assets/logo/logo-small.png" />
  </a>

  <span [ngClass]="['environment', environment]">
    {{ capitalize(environment) }}
  </span>

  <nav class="breadcrumbs">
    <span class="breadcrumbs-item">
      <span>ADMIN</span>
    </span>
    @for (
      breadcrumb of breadcrumbsService.breadcrumbs$ | async;
      track breadcrumb.label
    ) {
      <span class="breadcrumbs-item">
        @if (breadcrumb.link) {
          <a
            [routerLink]="breadcrumb.link"
            [skElementId]="elementId.Admin.BreadcrumbLink"
          >
            {{ breadcrumb.label }}
          </a>
        } @else {
          <span class="non-link">
            {{ breadcrumb.label }}
          </span>
        }
      </span>
    }
  </nav>

  <div class="spacer"></div>

  @if (authenticated) {
    @if (isFeatureFlagsEnabled()) {
      <a
        [routerLink]="['/feature-flags']"
        mat-flat-button
        color="secondary"
        [skElementId]="elementId.Admin.HeaderMenuItemFeatureFlags"
      >
        Feature Flags
      </a>
      <a
        [routerLink]="['/cohorts']"
        mat-flat-button
        color="secondary"
        [skElementId]="elementId.Admin.HeaderMenuItemFeatureFlags"
      >
        Cohorts
      </a>
    }
    <a
      [routerLink]="['/users']"
      mat-flat-button
      color="secondary"
      [skElementId]="elementId.Admin.HeaderMenuItemUsers"
    >
      Users
    </a>
    <a
      [routerLink]="['/agencies']"
      mat-flat-button
      color="secondary"
      [skElementId]="elementId.Admin.HeaderMenuItemAgencies"
    >
      Agencies
    </a>
    @if (vsdpAccess()) {
      <a
        [routerLink]="['/slide-definitions']"
        mat-flat-button
        color="secondary"
      >
        Slide Definitions
      </a>
    }
    @if (vsdpAccess()) {
      <a
        [routerLink]="['/slide-definition-layouts']"
        mat-flat-button
        color="secondary"
      >
        Slide Definition Layouts
      </a>
    }
    |
    <button (click)="logout()" mat-flat-button color="secondary">
      Log out
    </button>
  }
</div>
