import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatLabel } from '@angular/material/form-field';

import {
  CustomerType as CustomerTypes,
  ElementId,
  ElementIdType,
} from '@storykit/constants';
import { ElementIdDirective } from '@storykit/ui-components';

@Component({
  selector: 'app-premium-features-form',
  templateUrl: './premium-features-form.component.html',
  styleUrls: [
    './premium-features-form.component.scss',
    '../../../../../styles/settings-form.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, MatLabel, MatCheckbox, ElementIdDirective],
  standalone: true,
})
export class PremiumFeaturesFormComponent {
  form = input.required<FormGroup>();

  e2eVideoBlocks: ElementIdType = ElementId.Admin.AgencyCheckboxVideoblocks;
  e2eGetty: ElementIdType = ElementId.Admin.AgencyCheckboxGetty;
  e2ePremiumMusic: ElementIdType = ElementId.Admin.AgencyCheckboxPremiumMusic;
  e2eScriptCreator: ElementIdType = ElementId.Admin.AgencyCheckboxScriptCreator;
  e2eAssetLibrary: ElementIdType = ElementId.Admin.AgencyCheckboxAssetLibrary;

  public customerTypes = CustomerTypes;
}
