import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { CwsService } from '@core/services/cws.service';
import { MATERIAL_MODULES } from '@shared/material-design/material-modules';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-cohorts',
  standalone: true,
  imports: [MATERIAL_MODULES, CommonModule, RouterLink],
  templateUrl: './cohorts.component.html',
  styleUrl: './cohorts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CohortsViewComponent {
  cohorts$ = this.cwsService.getCohorts().pipe(map(({ cohorts }) => cohorts));

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private cwsService: CwsService,
    private route: Router
  ) {
    this.breadcrumbsService.set([{ label: 'Cohorts', link: '/cohorts' }]);
  }

  async createCohort(name: string) {
    const cohort = await lastValueFrom(this.cwsService.createCohort(name));
    void this.route.navigate(['cohorts', cohort._id]);
  }
}
