<div [formGroup]="form">
  <div class="user-form-section">
    <div class="user-form-field">
      <mat-label class="form-label">First name</mat-label>
      <mat-form-field>
        <input
          #in
          type="text"
          matInput
          formControlName="firstName"
          placeholder="First name"
          autocomplete="off"
          [skElementId]="elementId.Admin.UserInputFieldFirstName"
        />
      </mat-form-field>
    </div>
    <div class="user-form-field">
      <mat-label class="form-label">Last name</mat-label>
      <mat-form-field>
        <input
          #in
          type="text"
          matInput
          formControlName="lastName"
          placeholder="Last name"
          autocomplete="off"
          [skElementId]="elementId.Admin.UserInputFieldLastName"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="user-form-section">
    <div class="user-form-field">
      <mat-label class="form-label">
        Email
        @if (verified !== undefined) {
          <span class="verified-container">
            <span
              [ngClass]="{
                verified: verified === true,
                'not-verified': verified === false,
              }"
            >
              {{ verified === false ? 'not' : '' }} verified
            </span>
            @if (verified === false) {
              <button
                class="new-button-primary-small"
                mat-flat-button
                color="primary"
                (click)="resendEmail()"
              >
                Resend Invite
              </button>
            }
          </span>
        }
      </mat-label>
      <mat-form-field>
        <input
          #in
          type="text"
          matInput
          formControlName="email"
          placeholder="Email"
          autocomplete="off"
          [skElementId]="elementId.Admin.UserInputFieldEmail"
        />
      </mat-form-field>
    </div>
    <div class="user-form-field">
      <mat-label class="form-label">Role</mat-label>
      <mat-form-field>
        <mat-select
          formControlName="role"
          [skElementId]="elementId.Admin.UserRoleSelection"
        >
          @for (role of userRoles; track role; let i = $index) {
            <mat-option
              value="{{ role }}"
              [skElementId]="elementId.Admin.UserRoleSelectionOption"
              [skElementIdIndex]="i"
            >
              {{ userRoleLabels[role] }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="user-form-section">
    <div class="user-form-field">
      <mat-label class="form-label">Preferred language</mat-label>
      <mat-form-field>
        <mat-select formControlName="preferredLanguage">
          @for (language of languages; track language) {
            <mat-option value="{{ language }}">
              {{ languageLabels[language] }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    <div class="user-form-field"></div>
  </div>

  <div formGroupName="sso">
    <div class="user-form-section">
      <div class="user-form-field">
        <mat-label class="form-label">Single Sign-On</mat-label>
        <mat-checkbox formControlName="enabled">Enabled</mat-checkbox>
        <div class="sso-warning">
          No password email sent but requires SSO configurations by admin.
          Contact Tech if this should be added to an existing user.
        </div>
      </div>
    </div>

    <div class="user-form-section">
      <div class="user-form-field">
        <mat-label class="form-label">Connection Name</mat-label>
        <mat-form-field>
          <input
            #in
            type="text"
            matInput
            formControlName="connectionName"
            placeholder="Name of Enterprise Connection in Auth0"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="user-form-field">
        <mat-label class="form-label">Provider</mat-label>
        <mat-form-field>
          <input
            #in
            type="text"
            matInput
            formControlName="provider"
            placeholder="Last name"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
    </div>
  </div>
  @if (form.invalid) {
    <div class="form-validation-errors">
      @if (form.errors?.roleByEmail) {
        <span>
          Role Storykit Administrator can be chosen only for users with emails
          which correspond to such patterns:
          <i>{{ adminEmailPatterns }}</i>
        </span>
      }
    </div>
  }
</div>
