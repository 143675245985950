<div class="search-container">
  <div class="align-right">
    <button mat-raised-button color="primary" [matMenuTriggerFor]="menu">
      Slide definition layout actions
    </button>
    <mat-menu #menu="matMenu">
      <a [routerLink]="['/slide-definition-layouts/add']" mat-button>
        <app-icon name="users-three" weight="fill" class="menu-button-icon" />

        <span>Create slide definition layout</span>
      </a>
    </mat-menu>
  </div>
</div>

<div class="container list-container">
  <div class="list-container-page">
    <h3>Slide Definition Layouts</h3>
    <mat-table mat-table [dataSource]="dataSource" class="mat-elevation-z0">
      <ng-container matColumnDef="displayName">
        <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell *matCellDef="let slideDefinitionLayout">
          <a
            [routerLink]="[
              '/slide-definition-layouts',
              slideDefinitionLayout._id,
            ]"
          >
            {{ slideDefinitionLayout.displayName }}
          </a>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let slideDefinitionLayout">
          <a
            [routerLink]="[
              '/slide-definition-layouts',
              slideDefinitionLayout._id,
            ]"
            mat-raised-button
            color="primary"
          >
            Edit
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</div>
