import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
  standalone: true,
  imports: [AsyncPipe],
})
export class ErrorComponent implements OnInit {
  @Input() observable$!: Observable<unknown>;

  error$!: Observable<void>;

  ngOnInit(): void {
    this.error$ = this.observable$.pipe(
      map(() => null),
      catchError((error) => of(error))
    );
  }
}
