import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input } from '@angular/core';
import { NotificationService } from '@shared/notification/notification.service';

import { ElementId } from '@storykit/constants';

@Component({
  selector: 'app-input-to-clipboard',
  templateUrl: './input-to-clipboard.component.html',
  styleUrls: [
    './input-to-clipboard.component.scss',
    '../../../../styles/settings-form.scss',
  ],
})
export class InputToClipboardComponent {
  @Input() content!: string | null;

  @Input() label!: string;

  @Input() notificationMsg = 'Content was copied';

  elementId = ElementId;

  constructor(
    private clipboard: Clipboard,
    private notification: NotificationService
  ) {}

  copyToClipboard() {
    if (this.content) {
      this.clipboard.copy(this.content);
      this.notification.show('success', this.notificationMsg);
    }
  }
}
