import { ModuleWithProviders, NgModule } from '@angular/core';

import { Auth0Environment, ServiceName } from '@storykit/auth-client';

import { AUTH_CONFIG } from './auth.config';

@NgModule()
export class AuthClientModule {
  static forRoot(
    auth0Env: Auth0Environment,
    serviceName?: ServiceName
  ): ModuleWithProviders<AuthClientModule> {
    return {
      ngModule: AuthClientModule,
      providers: [
        {
          provide: AUTH_CONFIG,
          useValue: { auth0Env, serviceName },
        },
      ],
    };
  }
}
