import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard } from '@core/auth/authenticated.guard';
import { LoginComponent } from '@core/auth/login/login.component';
import { NotAllowedComponent } from '@core/auth/not-allowed/not-allowed.component';
import { NotAuthenticatedGuard } from '@core/auth/notAuthenticated.guard';
import { RedirectComponent } from '@core/auth/redirect/redirect.component';
import { FeatureGuard } from '@core/feature';
import { Modes } from '@models/modes';
import { AgencyListComponent } from '@views/agency/agency-list/agency-list.component';
import { AgencyComponent } from '@views/agency/agency/agency.component';
import { CohortViewComponent } from '@views/cohorts/cohort/cohort.component';
import { CohortsViewComponent } from '@views/cohorts/cohorts/cohorts.component';
import { FeatureFlagFormComponent } from '@views/feature-flags/feature-flag-form/feature-flag-form.component';
import { FeatureFlagsViewComponent } from '@views/feature-flags/feature-flags.component';
import { SlideDefinitionLayoutListComponent } from '@views/slide-definition-layout/slide-definition-layout-list/slide-definition-layout-list.component';
import { SlideDefinitionLayoutComponent } from '@views/slide-definition-layout/slide-definition-layout/slide-definition-layout.component';
import { SlideDefinitionListComponent } from '@views/slide-definition/slide-definition-list/slide-definition-list.component';
import { SlideDefinitionComponent } from '@views/slide-definition/slide-definition/slide-definition.component';
import { UserBulkAddComponent } from '@views/user/user-bulk/user-bulk-add.component';
import { UserListViewComponent } from '@views/user/user-list/user-list.component';
import { UserComponent } from '@views/user/user/user.component';

import { AppLoggedInComponent } from './appLoggedIn.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'redirect',
    component: RedirectComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: 'not-allowed',
    component: NotAllowedComponent,
    canActivate: [NotAuthenticatedGuard],
  },
  {
    path: '',
    component: AppLoggedInComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: 'feature-flags',
        component: FeatureFlagsViewComponent,
        canActivate: [FeatureGuard],
      },
      {
        path: 'feature-flags/:featureFlagId',
        component: FeatureFlagFormComponent,
        canActivate: [FeatureGuard],
      },
      {
        path: 'cohorts',
        component: CohortsViewComponent,
        canActivate: [FeatureGuard],
      },
      {
        path: 'cohorts/:cohortId',
        component: CohortViewComponent,
        canActivate: [FeatureGuard],
      },
      {
        path: 'users',
        component: UserListViewComponent,
        canActivate: [FeatureGuard],
      },
      {
        path: 'users/add',
        component: UserComponent,
        canActivate: [FeatureGuard],
        data: { mode: Modes.Add },
      },
      {
        path: 'users/:userId',
        component: UserComponent,
        canActivate: [FeatureGuard],
        data: { mode: Modes.Edit },
      },
      {
        path: 'users/bulk/add',
        component: UserBulkAddComponent,
        canActivate: [FeatureGuard],
      },
      {
        path: 'agencies',
        component: AgencyListComponent,
        canActivate: [FeatureGuard],
      },
      {
        path: 'agencies/add',
        component: AgencyComponent,
        canActivate: [FeatureGuard],
        data: { mode: Modes.Add },
      },
      {
        path: 'agencies/:agencyId',
        component: AgencyComponent,
        canActivate: [FeatureGuard],
        data: { mode: Modes.Edit },
      },
      {
        path: 'slide-definitions',
        component: SlideDefinitionListComponent,
        canActivate: [FeatureGuard],
      },
      {
        path: 'slide-definitions/add',
        component: SlideDefinitionComponent,
        canActivate: [FeatureGuard],
        data: { mode: Modes.Add },
      },
      {
        path: 'slide-definitions/:slideDefinitionId',
        component: SlideDefinitionComponent,
        canActivate: [FeatureGuard],
        data: { mode: Modes.Edit },
      },
      {
        path: 'slide-definition-layouts',
        component: SlideDefinitionLayoutListComponent,
        canActivate: [FeatureGuard],
      },
      {
        path: 'slide-definition-layouts/add',
        component: SlideDefinitionLayoutComponent,
        canActivate: [FeatureGuard],
        data: { mode: Modes.Add },
      },
      {
        path: 'slide-definition-layouts/:slideDefinitionLayoutId',
        component: SlideDefinitionLayoutComponent,
        canActivate: [FeatureGuard],
        data: { mode: Modes.Edit },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
