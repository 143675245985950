<form
  [formGroup]="formGroup()"
  method="POST"
  (submit)="submitAddFeed(errorDialog)"
>
  <mat-form-field>
    <mat-label>Feed Name</mat-label>
    <input matInput formControlName="name" type="text" />
    @if (
      formGroup().controls.name.invalid &&
      (formGroup().controls.name.touched || formGroup().controls.name.dirty)
    ) {
      <mat-error>{{ formGroup().controls.name.errors | json }}</mat-error>
    }
    <mat-hint>Will also change the name of the Folder</mat-hint>
  </mat-form-field>

  <mat-form-field
    matTooltip="Note that changing the URL into one with different feed items will make the processing lose its current 'bookmark' and processing will start from scratch"
    matTooltipPosition="above"
  >
    <mat-label>Feed XML URL</mat-label>
    <input
      matInput
      formControlName="feedUrl"
      type="text"
      placeholder="https://..."
    />
    @if (
      formGroup().controls.feedUrl.invalid &&
      (formGroup().controls.feedUrl.touched ||
        formGroup().controls.feedUrl.dirty)
    ) {
      <mat-error>{{ formGroup().controls.feedUrl.errors | json }}</mat-error>
    }
  </mat-form-field>
  @if (themes().length > 0) {
    <mat-form-field>
      <mat-label>Theme</mat-label>
      <mat-select formControlName="themeId">
        @for (theme of themes(); track theme.id) {
          <mat-option [value]="theme.id">{{ theme.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
    @if (
      formGroup().controls.themeId.invalid &&
      (formGroup().controls.themeId.touched ||
        formGroup().controls.themeId.dirty)
    ) {
      <mat-error>{{ formGroup().controls.themeId.errors | json }}</mat-error>
    }
  } @else {
    <mat-form-field>
      <mat-label>Theme ID</mat-label>
      <input
        matInput
        formControlName="themeId"
        type="text"
        placeholder="e.g. 60dc5c746f174f004c415eee"
      />
    </mat-form-field>
    @if (
      formGroup().controls.themeId.invalid &&
      (formGroup().controls.themeId.touched ||
        formGroup().controls.themeId.dirty)
    ) {
      <mat-error>{{ formGroup().controls.themeId.errors | json }}</mat-error>
    }
  }

  @if (campaigns().length > 0) {
    <mat-form-field>
      <mat-label>Campaign</mat-label>
      <mat-select formControlName="campaignId">
        @for (campaign of campaigns(); track campaign.id) {
          <mat-option [value]="campaign.id" style="line-height: 1">
            <strong>{{ campaign.name }}</strong>
            {{ campaign.isCustom ? ' (custom)' : '' }}
            {{
              campaign.campaignCreators.length > 1
                ? '(' + campaign.campaignCreators.length + ' creators)'
                : ''
            }}<br />
            <small style="white-space: nowrap">{{
              campaign.description
            }}</small>
          </mat-option>
        }
      </mat-select>
      @if (
        formGroup().controls.campaignId.invalid &&
        (formGroup().controls.campaignId.touched ||
          formGroup().controls.campaignId.dirty)
      ) {
        <mat-error>{{
          formGroup().controls.campaignId.errors | json
        }}</mat-error>
      }
    </mat-form-field>
  } @else {
    <mat-form-field>
      <mat-label>Campaign ID</mat-label>
      <input
        matInput
        formControlName="campaignId"
        type="text"
        placeholder="e.g. d2fc8ca6-5cb2-4309-80a3-256401386e09"
      />
      @if (
        formGroup().controls.campaignId.invalid &&
        (formGroup().controls.campaignId.touched ||
          formGroup().controls.campaignId.dirty)
      ) {
        <mat-error>{{
          formGroup().controls.campaignId.errors | json
        }}</mat-error>
      }
    </mat-form-field>
  }

  <mat-form-field>
    <mat-label>Notification E-mails</mat-label>
    <textarea
      matInput
      formControlName="notificationEmail"
      [rows]="feedToEdit().settings.notificationRecipients.length + 2"
      type="text"
      placeholder="e.g. eam@storykit.io, person@customer.com"
    ></textarea>

    <mat-hint>Separate multiple e-mails with a comma</mat-hint>
    @if (formGroup().controls.notificationEmail.invalid) {
      <mat-error>{{
        formGroup().controls.notificationEmail.errors | json
      }}</mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label>Asset Library</mat-label>
    <input
      type="text"
      matInput
      formControlName="assetLibraryId"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete">
      @for (assetLibrary of assetLibraries(); track assetLibrary.id) {
        <mat-option [value]="assetLibrary.id"
          >{{ assetLibrary.name }} ({{ assetLibrary.id }})</mat-option
        >
      }
    </mat-autocomplete>
    @if (
      formGroup().controls.assetLibraryId.invalid &&
      (formGroup().controls.assetLibraryId.touched ||
        formGroup().controls.assetLibraryId.dirty)
    ) {
      <mat-error>{{
        formGroup().controls.assetLibraryId.errors | json
      }}</mat-error>
    }
  </mat-form-field>

  <div class="languages">
    <mat-form-field>
      <mat-label>Source language</mat-label>
      <mat-select formControlName="sourceLanguage">
        @for (language of languages(); track language) {
          <mat-option [value]="language">{{ language | titlecase }}</mat-option>
        }
      </mat-select>
      <mat-hint>Fallback when no source language is determined</mat-hint>
      @if (formGroup().controls.sourceLanguage.invalid) {
        <mat-error>{{
          formGroup().controls.sourceLanguage.errors | json
        }}</mat-error>
      }
    </mat-form-field>

    <mat-form-field>
      <mat-label>Output language</mat-label>
      <mat-select formControlName="outputLanguage">
        @for (language of languages(); track language) {
          <mat-option [value]="language">{{ language | titlecase }}</mat-option>
        }
      </mat-select>
      <mat-hint>Fallback when no output language is determined</mat-hint>
      @if (formGroup().controls.sourceLanguage.invalid) {
        <mat-error>{{
          formGroup().controls.sourceLanguage.errors | json
        }}</mat-error>
      }
    </mat-form-field>
  </div>
  <div class="submit">
    <button
      type="submit"
      mat-flat-button
      color="primary"
      [disabled]="loading()"
    >
      Save
    </button>
    <button type="button" mat-flat-button (click)="canceled.emit()">
      Cancel
    </button>
    @if (loading()) {
      <mat-progress-bar mode="buffer"></mat-progress-bar>
    }
  </div>

  <ng-template #errorDialog let-data>
    <h2 mat-dialog-title>Failed to update feed!</h2>
    <mat-dialog-content>
      <pre>{{ data.error | json }}</pre>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button [mat-dialog-close]="true">Close</button>
    </mat-dialog-actions>
  </ng-template>
</form>
