import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VideoSlideDefinitionLayout } from '@storykit/typings/src/cws';

import { ApiService } from './api.service';

@Injectable()
export class SlideDefinitionLayoutService {
  readonly baseUrl = `${environment.api.cws.endpoint}/videostudio/definition-layouts`;

  constructor(private apiService: ApiService) {}

  listSlideDefinitionLayouts(): Observable<
    VideoSlideDefinitionLayout.ListVideoSlideDefinitionLayout['response']
  > {
    return this.apiService
      .call<VideoSlideDefinitionLayout.ListVideoSlideDefinitionLayout>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition-layouts',
        query: {},
        params: {},
        body: null,
        method: 'GET',
      })
      .pipe(map(({ body }) => body));
  }

  getSlideDefinitionLayout(
    id: string
  ): Observable<
    VideoSlideDefinitionLayout.GetVideoSlideDefinitionLayout['response']
  > {
    return this.apiService
      .call<VideoSlideDefinitionLayout.GetVideoSlideDefinitionLayout>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition-layouts/:layoutId',
        query: {},
        params: {
          layoutId: id,
        },
        body: null,
        method: 'GET',
      })
      .pipe(map(({ body }) => body));
  }

  createSlideDefinitionLayout(
    slideDefinitionLayout: VideoSlideDefinitionLayout.CreateVideoSlideDefinitionLayout['body']
  ): Observable<
    VideoSlideDefinitionLayout.CreateVideoSlideDefinitionLayout['response']
  > {
    return this.apiService
      .call<VideoSlideDefinitionLayout.CreateVideoSlideDefinitionLayout>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition-layouts',
        query: {},
        params: {},
        body: slideDefinitionLayout,
        method: 'POST',
      })
      .pipe(map(({ body }) => body));
  }

  updateSlideDefinitionLayout(
    id: string,
    slideDefinitionLayout: VideoSlideDefinitionLayout.UpdateVideoSlideDefinitionLayout['body']
  ): Observable<
    VideoSlideDefinitionLayout.UpdateVideoSlideDefinitionLayout['response']
  > {
    return this.apiService
      .call<VideoSlideDefinitionLayout.UpdateVideoSlideDefinitionLayout>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition-layouts/:layoutId',
        query: {},
        params: {
          layoutId: id,
        },
        body: slideDefinitionLayout,
        method: 'PUT',
      })
      .pipe(map(({ body }) => body));
  }

  deleteSlideDefinitionLayout(
    id: string
  ): Observable<
    VideoSlideDefinitionLayout.DeleteVideoSlideDefinitionLayout['response']
  > {
    return this.apiService
      .call<VideoSlideDefinitionLayout.DeleteVideoSlideDefinitionLayout>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition-layouts/:layoutId',
        query: {},
        params: {
          layoutId: id,
        },
        body: null,
        method: 'DELETE',
      })
      .pipe(map(({ body }) => body));
  }
}
