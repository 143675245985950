@if (loading === false) {
  <div [formGroup]="form">
    <sk-file-drop
      (fileDrop)="dropped($event)"
      [useZip]="true"
      dropZoneLabel="Drag and drop files or click to upload"
      [showBrowseBtn]="true"
      [isLoading]="isUploading"
      accept=".ttf,.otf"
    ></sk-file-drop>
    <span [matTooltip]="disableUpload ? 'Add a file to upload' : ''">
      <button
        mat-flat-button
        color="primary"
        (click)="uploadFont()"
        class="upload-font-button"
        matTooltip="Upload font "
        [disabled]="disableUpload"
      >
        Upload font
      </button>
    </span>
    @if (dataSource.length > 0) {
      <table
        mat-table
        [dataSource]="displayedFonts"
        [multiTemplateDataRows]="true"
        class="mat-elevation-z0 font-table"
      >
        @for (column of headerColumns; track column) {
          <ng-container matColumnDef="{{ column }}">
            <th
              mat-header-cell
              *matHeaderCellDef
              [ngClass]="{ actions: notDisplayedColumns.includes(column) }"
            >
              @if (notDisplayedColumns.includes(column)) {
                <span style="display: none">{{ column }}</span>
              } @else {
                {{ column }}
              }
            </th>
            <ng-container *matCellDef="let element; let dataIndex = dataIndex">
              @if (column === 'font') {
                <td class="font limit-text">
                  <span
                    style="cursor: pointer"
                    (click)="
                      expandedElement =
                        expandedElement === element ? null : element
                    "
                  >
                    <app-icon
                      [name]="
                        expandedElement === element
                          ? 'arrow-down'
                          : 'arrow-right'
                      "
                      weight="fill"
                    ></app-icon>
                  </span>
                  <span [matTooltip]="element.fontName"
                    >{{ element.fontName }}
                    @if (hasUnsavedFontValuesChanges[dataIndex]) {
                      <span class="font-unsaved-change"> ! </span>
                    }
                  </span>
                </td>
              } @else if (column === 'weight') {
                <td class="custom-weight-header">
                  {{ element.fontWeight }}
                </td>
              } @else if (column === 'size') {
                <td class="custom-size-header">
                  {{ element.large.size }}
                </td>
              } @else if (column === 'leading') {
                <td class="custom-leading-header">
                  {{ element.large.leading }}
                </td>
              } @else if (column === 'tracking') {
                <td class="custom-tracking-header">
                  {{ element.large.tracking }}
                </td>
              } @else if (column === 'capHeight') {
                <td class="custom-capHeight-header">
                  {{ element.large.capHeight }}
                </td>
              } @else if (column === 'xHeight') {
                <td class="custom-xHeight-header">
                  {{ element.large.xHeight }}
                </td>
              } @else if (column === 'uploaded-by') {
                <td class="custom-uploaded-by-header">
                  {{ element.createdBy?.name }}
                </td>
              } @else if (column === 'lock') {
                @if (!this.disabledInput.has(dataIndex)) {
                  <td class="text-align--center">
                    <button
                      class="no-button-style"
                      (click)="lockRow(dataIndex, element?._id)"
                      matTooltip="Locks selection for changes"
                    >
                      <app-icon name="lock-open" weight="fill" />
                    </button>
                  </td>
                } @else {
                  <td class="text-align--center">
                    <button
                      class="no-button-style"
                      matTooltip="Unlocks selection for changes"
                      (click)="unlockRow(dataIndex, element?._id)"
                    >
                      <app-icon name="lock" weight="fill" />
                    </button>
                  </td>
                }
              } @else if (column === 'download') {
                <td class="text-align--center">
                  <button
                    class="no-button-style dark-button"
                    matTooltip="Download font"
                    (click)="downloadFont(dataIndex)"
                  >
                    <app-icon name="cloud-arrow-down" weight="fill" />
                  </button>
                </td>
              } @else if (column === 'trashcan') {
                <td class="text-align--center">
                  <span
                    [matTooltip]="
                      element.global
                        ? 'This font is global and cannot be deleted'
                        : 'Delete font'
                    "
                  >
                    <button
                      class="no-button-style"
                      [ngClass]="{ 'negative-button': !element.global }"
                      [disabled]="element.global"
                      (click)="openDeleteDialog(dataIndex)"
                    >
                      <app-icon name="trash" weight="fill" />
                    </button>
                  </span>
                </td>
              } @else if (column === 'action') {
                <td class="text-align--center">
                  <div class="mat-select-wrapper">
                    <mat-form-field
                      [matTooltip]="
                        clientsFontControls[element?._id].disabled
                          ? 'Unlock a font to change clients access'
                          : 'Clients access to the font'
                      "
                    >
                      @if (
                        fontClients[element?._id] &&
                        fontClients[element?._id].length !== 0
                      ) {
                        <mat-select
                          [formControl]="clientsFontControls[element?._id]"
                          multiple
                          [compareWith]="objectComparisonFunction"
                        >
                          <mat-select-trigger
                            >Accessing Clients
                          </mat-select-trigger>
                          @for (
                            client of fontClients[element?._id];
                            track client
                          ) {
                            <mat-option [value]="client"
                              >{{ client?.name }}
                            </mat-option>
                          }
                        </mat-select>
                      }
                      <!-- need to show only no clients option-->
                      <!-- mat-select don't support dynamic [multiple] option -->
                      <!-- https://material.angular.io/components/select/overview#error-cannot-change-multiple-mode-of-select-after-initialization -->
                      @if (fontClients[element?._id].length === 0) {
                        <mat-select
                          [formControl]="clientsFontControls[element?._id]"
                        >
                          <mat-select-trigger>No clients</mat-select-trigger>
                          @if (fontClients[element?._id].length === 0) {
                            <mat-option [value]="null" disabled="true"
                              >No clients to choose
                            </mat-option>
                          }
                        </mat-select>
                      }
                    </mat-form-field>
                  </div>
                </td>
              }
            </ng-container>
          </ng-container>
        }
        <ng-container matColumnDef="expandedDetail">
          <td
            *matCellDef="let element; let dataIndex = dataIndex"
            [attr.colspan]="headerColumns.length"
          >
            <div
              class="element-detail"
              [@detailExpand]="
                element === expandedElement ? 'expanded' : 'collapsed'
              "
            >
              @for (
                access of fontFormControls | async;
                track access;
                let i = $index
              ) {
                <div formArrayName="fontSettings{{ dataIndex }}">
                  <div
                    class="element-detail-row"
                    [formGroupName]="i"
                    [class.disabled-row]="this.disabledInput.has(dataIndex)"
                  >
                    @if (access.value.fontStyle === masterFont) {
                      <mat-cell style="flex: 0 0 18%">
                        <b>{{ access.value.fontStyle }} (Master) </b>
                      </mat-cell>
                    } @else {
                      <mat-cell style="flex: 0 0 18%">
                        {{ access.value.fontStyle }}
                      </mat-cell>
                    }
                    <mat-cell class="flex-14">
                      <mat-form-field class="form-field-small dash">
                        <input
                          type="number"
                          matInput
                          formControlName="size"
                          placeholder="size"
                          autocomplete="off"
                          (change)="onFontValuesChange(dataIndex)"
                        />
                      </mat-form-field>
                    </mat-cell>
                    <mat-cell class="flex-14">
                      <mat-form-field class="form-field-small dash">
                        <input
                          type="number"
                          matInput
                          formControlName="leading"
                          placeholder="leading"
                          autocomplete="off"
                          (change)="onFontValuesChange(dataIndex)"
                        />
                      </mat-form-field>
                    </mat-cell>
                    <mat-cell class="flex-14">
                      <mat-form-field class="form-field-small dash">
                        <input
                          type="number"
                          matInput
                          formControlName="tracking"
                          placeholder="tracking"
                          autocomplete="off"
                          (change)="onFontValuesChange(dataIndex)"
                        />
                      </mat-form-field>
                    </mat-cell>
                    <mat-cell class="flex-14">
                      <mat-form-field class="form-field-small dash">
                        <input
                          type="number"
                          matInput
                          formControlName="capHeight"
                          placeholder="cap height"
                          autocomplete="off"
                          (change)="onFontValuesChange(dataIndex)"
                        />
                      </mat-form-field>
                    </mat-cell>
                    <mat-cell>
                      <mat-form-field class="form-field-small">
                        <input
                          type="number"
                          matInput
                          formControlName="xHeight"
                          placeholder="x height"
                          autocomplete="off"
                          (change)="onFontValuesChange(dataIndex)"
                        />
                      </mat-form-field>
                    </mat-cell>
                  </div>
                </div>
              }
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="headerColumns"></tr>
        <tr
          mat-row
          *matRowDef="
            let element;
            columns: headerColumns;
            let dataIndex = dataIndex
          "
          [class.expanded-row]="expandedElement === element"
          [class.disabled-row]="this.disabledInput.has(dataIndex)"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="detail-row"
        ></tr>
      </table>
      <mat-paginator
        [length]="dataSource.length"
        (page)="pageInit($event)"
        [pageSizeOptions]="[5, 10, 20]"
        [showFirstLastButtons]="true"
      ></mat-paginator>
      <div class="update-buttons-container">
        <span [matTooltip]="!form.enabled ? 'Unlock a font to update' : ''">
          <button
            mat-flat-button
            color="primary"
            (click)="updateFonts()"
            matTooltip="Updates unlocked fonts for agency"
            [disabled]="formLoading || form.invalid || !form.enabled"
          >
            Update font values
          </button>
          <button
            mat-flat-button
            color="primary"
            (click)="updateClients()"
            class="update-clients-button"
            matTooltip="Updates client access for fonts"
            [disabled]="formLoading || form.invalid || !form.enabled"
          >
            Update client access
          </button>
        </span>
      </div>
    } @else {
      <div class="container container--small text-align--center">
        <p>No fonts uploaded to agency</p>
      </div>
    }
  </div>
}
