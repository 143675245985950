<mat-expansion-panel [expanded]="false">
  <mat-expansion-panel-header>
    <mat-panel-title>
      <div style="display: flex; align-items: baseline; gap: 5px">
        @if (cohort().active) {
          <app-icon
            [name]="cohort().active ? 'check-circle' : 'x-circle'"
            weight="fill"
            class="icon-{{ cohort().active ? 'positive' : 'negative' }}"
          ></app-icon>
        }
        <a [routerLink]="['/cohorts', cohort()._id]">
          {{ cohort().name }}
        </a>
      </div>
    </mat-panel-title>
    <mat-panel-description>
      {{ cohort().description }}
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ul style="list-style: none; margin: 0; padding: 0 0 0 15px">
    @for (user of cohort().users; track user.id || user.email?.text) {
      <li>
        @if (user.id) {
          {{ user.id }}
        } @else {
          {{ user.email?.comparator }}
          {{ user.email?.text }}
        }
      </li>
    }
  </ul>
</mat-expansion-panel>
