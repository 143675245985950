<div [formGroup]="form">
  <mat-table
    mat-table
    [dataSource]="dataSource"
    formArrayName="users"
    class="mat-elevation-z0"
  >
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    <ng-container matColumnDef="firstName">
      <mat-header-cell *matHeaderCellDef> First name</mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field subscriptSizing="dynamic">
          <input
            matInput
            type="text"
            autocomplete="off"
            formControlName="firstName"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <mat-header-cell *matHeaderCellDef> Last name</mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field subscriptSizing="dynamic">
          <input
            matInput
            type="text"
            autocomplete="off"
            formControlName="lastName"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef> Email</mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field subscriptSizing="dynamic">
          <input
            matInput
            type="text"
            autocomplete="off"
            formControlName="email"
          />
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef> Role</mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field subscriptSizing="dynamic">
          <mat-select formControlName="role">
            @for (role of userRoles; track role) {
              <mat-option value="{{ role }}">
                {{ userRoleLabels[role] }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="language">
      <mat-header-cell *matHeaderCellDef> Language</mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        <mat-form-field subscriptSizing="dynamic">
          <mat-select formControlName="preferredLanguage">
            @for (language of languages; track language) {
              <mat-option value="{{ language }}"
                >{{ languageLabels[language] }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remove">
      <mat-header-cell *matHeaderCellDef> Remove</mat-header-cell>

      <mat-cell
        *matCellDef="let row; let index = index"
        [formGroupName]="index"
      >
        @if (dataSource.value.length > 1) {
          <button
            mat-icon-button
            [disabled]="row.value.disabled"
            (click)="deleteUserRow(index)"
          >
            <app-icon
              name="trash"
              [ngClass]="{
                'trash-icon-disabled': row.value.disabled,
                'trash-icon': !row.value.disabled,
              }"
            ></app-icon>
          </button>
        }
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef> Saved</mat-header-cell>

      <mat-cell *matCellDef="let row; let index = index">
        @switch (row.value.status) {
          @case (statusCodes.InProgress) {
            <mat-spinner [value]="100" [diameter]="20"></mat-spinner>
          }
          @case (statusCodes.Success) {
            <app-icon name="check" class="icon-success" />
          }
          @case (statusCodes.Error) {
            <span [matTooltip]="row.value.errorMessage">
              <app-icon name="bug" class="icon-error" />
            </span>
          }
        }
      </mat-cell>
    </ng-container>
  </mat-table>
</div>
