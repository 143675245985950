import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';
import { CwsService } from '@core/services/cws.service';
import { MATERIAL_MODULES } from '@shared/material-design/material-modules';
import { NotificationService } from '@shared/notification/notification.service';
import { UserAutocompleteSelectboxComponent } from '@views/feature-flags/user-autocomplete-selectbox/user-autocomplete-selectbox.component';
import { ConfirmDialogComponent } from '@views/partials/confirm-dialog/confirm-dialog.component';
import { lastValueFrom } from 'rxjs';

import { UserWildcardComparator } from '@storykit/constants';
import { UpdateCohortBody } from '@storykit/typings/src/cws/feature-flag';

interface FormType {
  name: FormControl<string>;
  description: FormControl<string | null>;
  users: FormArray<
    FormGroup<{
      idOrEmail: FormControl<boolean>;
      id: FormControl<string>;
      email: FormControl<string>;
      comparator: FormControl<string>;
    }>
  >;
}

@Component({
  selector: 'app-cohort',
  standalone: true,
  imports: [
    MATERIAL_MODULES,
    ReactiveFormsModule,
    UserAutocompleteSelectboxComponent,
  ],
  templateUrl: './cohort.component.html',
  styleUrl: './cohort.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CohortViewComponent implements OnInit {
  form: FormGroup<FormType> | null = null;
  error: string | null = null;

  usersDataSource: FormType['users']['value'] = [];
  displayedColumns = ['idOrEmail', 'input', 'actions'];

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private cwsService: CwsService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private notification: NotificationService
  ) {
    this.breadcrumbsService.set([
      { label: 'Cohorts', link: '/cohorts' },
      { label: 'Cohort' },
    ]);
  }

  ngOnInit() {
    const cohortId = this.route.snapshot.paramMap.get('cohortId');
    if (cohortId) {
      void lastValueFrom(this.cwsService.getCohort(cohortId))
        .then((cohort) => {
          const control = this.formBuilder.nonNullable.control.bind(
            this.formBuilder.nonNullable.control
          );
          this.form = this.formBuilder.nonNullable.group<FormType>({
            name: control(cohort.name, Validators.required),
            description: control(cohort.description ?? ''),
            users: this.formBuilder.nonNullable.array(
              (cohort.users || []).map((user) =>
                this.formBuilder.nonNullable.group({
                  idOrEmail: control(!!user.email),
                  id: control(user.id ?? ''),
                  email: control(user.email?.text ?? ''),
                  comparator: control(
                    (user.email?.comparator ??
                      UserWildcardComparator.EXACT) as string,
                    Validators.required
                  ),
                })
              )
            ),
          });

          this.updateUsersDataSource();
        })
        .catch((error: Error) => {
          this.error = error.toString();
        });
    } else {
      this.error = 'Cohort not found';
    }
  }

  private updateUsersDataSource(): void {
    const usersArray = this.form?.get('users') as FormType['users'];
    this.usersDataSource = usersArray ? usersArray.value : [];
    this.cdr.markForCheck();
  }

  addUser() {
    const usersArray = this.form?.get('users') as FormType['users'];
    usersArray.push(
      this.formBuilder.nonNullable.group({
        idOrEmail: [true, Validators.required],
        id: [''],
        email: [''],
        comparator: [
          UserWildcardComparator.EXACT as string,
          Validators.required,
        ],
      })
    );
    this.updateUsersDataSource();
  }

  removeUser(index: number) {
    const usersArray = this.form?.get('users') as FormType['users'];
    usersArray.removeAt(index);
    this.updateUsersDataSource();
  }

  async deleteCohort() {
    const cohortId = this.route.snapshot.paramMap.get('cohortId');
    if (!cohortId) {
      return;
    }
    const confirmed = (await lastValueFrom(
      ConfirmDialogComponent.open(this.dialog, {
        action: 'Delete',
        subject: 'cohort',
      }).afterClosed()
    )) as boolean;

    if (!confirmed) {
      return;
    }

    await lastValueFrom(this.cwsService.deleteCohort(cohortId));
    void this.router.navigate(['/cohorts']);
  }

  async save() {
    const cohortId = this.route.snapshot.paramMap.get('cohortId');
    if (!cohortId || !this.form) {
      return;
    }

    const formValue = this.form.value;

    if (!formValue.name) {
      return;
    }

    const cohort: UpdateCohortBody = {
      _id: cohortId,
      name: formValue.name,
      description: formValue.description ?? '',
      users: (formValue.users ?? []).map((user) => ({
        id: user.idOrEmail ? undefined : user.id,
        email: user.idOrEmail
          ? {
              text: user.email ?? '',
              comparator:
                user.comparator === UserWildcardComparator.REGEX
                  ? UserWildcardComparator.REGEX
                  : UserWildcardComparator.EXACT,
            }
          : undefined,
      })),
    };

    await lastValueFrom(this.cwsService.saveCohort(cohortId, cohort));
    this.notification.show('success', 'Cohort saved');
  }

  isUserIdOrEmail(i: number) {
    const users = this.form?.get('users') as FormType['users'];
    if (!users?.length) {
      return false;
    }
    return users.at(i)?.get('idOrEmail')?.value;
  }
}
