import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ClientsAgencies } from '@models/cws';
import { UpdatedFonts } from '@models/font';
import { User } from '@models/user';
import { UserListQuery } from '@models/user-filter';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { Cws } from '@storykit/typings';
import {
  FeatureFlag,
  GetUsersResponse,
  RestartTrial,
  RestartTrialResponse,
  SignupSource,
  VideoFont,
} from '@storykit/typings/src/cws';
import {
  UpdateCohortBody,
  UpdateFeatureFlagBody,
} from '@storykit/typings/src/cws/feature-flag';

import { ApiService } from './api.service';
import { ObservableCacheService } from './observable-cache.service';

const PAGE_SIZE = 25;

@Injectable({
  providedIn: 'root',
})
export class CwsService {
  constructor(
    private api: ApiService,
    private cache: ObservableCacheService
  ) {}

  getFeatureFlag(flagId: string) {
    return this.api
      .call<FeatureFlag.GetFeatureFlagAdmin>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag/:flagId',
        params: { flagId },
        method: 'GET',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getFeatureFlags() {
    return this.api
      .call<FeatureFlag.GetFeatureFlagList>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag',
        params: {},
        method: 'GET',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getCohort(cohortId: string) {
    return this.api
      .call<FeatureFlag.GetCohort>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag/cohort/:cohortId',
        params: { cohortId },
        method: 'GET',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getCohorts() {
    return this.api
      .call<FeatureFlag.GetCohortList>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag/cohort',
        params: {},
        method: 'GET',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  createCohort(name: string) {
    return this.api
      .call<FeatureFlag.CreateCohort>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag/cohort',
        params: {},
        method: 'POST',
        query: {},
        body: { name },
      })
      .pipe(map(({ body }) => body));
  }

  createFeatureFlag(key: string) {
    return this.api
      .call<FeatureFlag.CreateFeatureFlag>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag',
        params: {},
        method: 'POST',
        query: {},
        body: {
          key,
          active: false,
        },
      })
      .pipe(map(({ body }) => body));
  }

  deleteFeatureFlag(flagId: string) {
    return this.api
      .call<FeatureFlag.DeleteFeatureFlag>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag/:flagId',
        params: { flagId },
        method: 'DELETE',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  deleteCohort(cohortId: string) {
    return this.api
      .call<FeatureFlag.DeleteCohort>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag/cohort/:cohortId',
        params: { cohortId },
        method: 'DELETE',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  saveFeatureFlag(flagId: string, body: UpdateFeatureFlagBody) {
    return this.api
      .call<FeatureFlag.UpdateFeatureFlag>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag/:flagId',
        params: { flagId },
        method: 'PUT',
        query: {},
        body,
      })
      .pipe(map(({ body }) => body));
  }

  saveCohort(cohortId: string, body: UpdateCohortBody) {
    return this.api
      .call<FeatureFlag.UpdateCohort>({
        origin: environment.api.cws.endpoint,
        path: '/feature-flag/cohort/:cohortId',
        params: { cohortId },
        method: 'PUT',
        query: {},
        body,
      })
      .pipe(map(({ body }) => body));
  }

  getAllFeatureFlagReasons(userId: string) {
    return this.api
      .call<Cws.GetUserFeatureFlags>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/feature-flags',
        params: { userId },
        method: 'GET',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getAgencies(): Observable<kit.IAgency[]> {
    return this.api
      .call<Cws.ListAgencies>({
        origin: environment.api.cws.endpoint,
        path: '/agency',
        params: {},
        method: 'GET',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getClients(): Observable<kit.IClient[]> {
    return this.api
      .call<Cws.ListClients>({
        origin: environment.api.cws.endpoint,
        path: '/client',
        params: {},
        method: 'GET',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  updateVideoSlideDefsCategory(definitionId: string, accessCategory: string) {
    return this.api
      .call<Cws.PutDefinition>({
        origin: environment.api.cws.endpoint,
        path: `/videostudio/definition/:definitionId`,
        params: { definitionId },
        method: 'PUT',
        query: null,
        body: { accessCategory },
      })
      .pipe(map(({ body }) => body));
  }

  getClientsAgencies(): Observable<ClientsAgencies> {
    return this.cache.get('clientsAgencies', this.httpGetClientsAgencies());
  }

  getClientsByAgencyId(agencyId: string): Observable<kit.IClient[]> {
    return this.getClientsAgencies().pipe(
      map((clientsAndAgency) =>
        clientsAndAgency.clients.filter(
          (client) => client.agency._id === agencyId
        )
      )
    );
  }

  updateFonts(font: UpdatedFonts[]) {
    return this.api
      .call<Cws.UpdateFont>({
        origin: environment.api.cws.endpoint,
        path: '/agency/font',
        params: {},
        method: 'PUT',
        query: null,
        body: font,
      })
      .pipe(map(({ body }) => body));
  }

  deleteFont(agencyId: string, fontId: string) {
    return this.api
      .call<Cws.DeleteFont>({
        origin: environment.api.cws.endpoint,
        path: `/agency/:agencyId/font/:fontId`,
        params: { agencyId, fontId },
        method: 'DELETE',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  listFonts(agencyId: string): Observable<VideoFont[]> {
    return this.api
      .call<Cws.GetFonts>({
        origin: environment.api.cws.endpoint,
        path: `/videostudio/font`,
        params: {},
        method: 'GET',
        query: {
          agency: agencyId,
          global: 'false',
        },
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getSignedFontUrl(
    agencyId: string,
    fontId: string
  ): Observable<{ url: string }> {
    return this.api
      .call<Cws.GetSignedFont>({
        origin: environment.api.cws.endpoint,
        path: '/agency/:agencyId/font/:fontId',
        params: { agencyId, fontId },
        query: undefined,
        method: 'GET',
        body: {},
      })
      .pipe(map(({ body }) => body));
  }

  setBlocked(userId: string, blocked: boolean) {
    return this.api
      .call<Cws.SetBlockedUser>({
        origin: environment.api.cws.endpoint,
        path: '/user/:userId/setBlocked',
        params: { userId },
        method: 'PUT',
        query: null,
        body: { blocked },
      })
      .pipe(map(({ body }) => body));
  }

  removeDeletedStatus(userId: string) {
    return this.api
      .call<Cws.RemoveDeletedStatus>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/remove-deleted-status',
        params: { userId },
        method: 'PUT',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getPasswordResetLink(userId: string) {
    return this.api
      .call<Cws.GetPasswordResetLink>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/passwordreset',
        params: { userId },
        method: 'GET',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  checkUserIpBlock(userId: string) {
    return this.api
      .call<Cws.GetBlockedIp>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/ip-block',
        params: { userId },
        method: 'GET',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  removeUserIpBlock(userId: string) {
    return this.api
      .call<Cws.RemoveBlockedIp>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/ip-block',
        params: { userId },
        method: 'DELETE',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  resendInviteEmail(userId: string) {
    return this.api
      .call<Cws.ResendInviteEmail>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/resend-invite-email',
        params: { userId },
        method: 'GET',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  updateUser(user: Cws.UpdateUserFromAdmin['body']): Observable<User> {
    return this.api
      .call<Cws.UpdateUserFromAdmin>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId',
        params: { userId: user._id },
        method: 'PUT',
        query: null,
        body: user,
      })
      .pipe(map(({ body }) => body));
  }

  deleteUser(userId: string) {
    return this.api
      .call<Cws.AnonymizeUser>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId',
        params: { userId },
        method: 'DELETE',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getUsers(
    userListQuery: Partial<UserListQuery> = {}
  ): Observable<GetUsersResponse> {
    return this.api
      .call<Cws.GetUsersAdmin>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users',
        params: {},
        method: 'GET',
        query: this.getUsersSearchParams(userListQuery),
        body: null,
      })
      .pipe(
        map(
          ({ body, headers }): GetUsersResponse => ({
            users: body || [],
            totalCount: headers.get('x-total')
              ? Number(headers.get('x-total'))
              : 0,
            pageSize: PAGE_SIZE,
          })
        )
      );
  }

  getUser(userId: string): Observable<User> {
    return this.api
      .call<Cws.GetUserFromAdmin>({
        origin: environment.api.cws.endpoint,
        path: '/admin/user/:userId',
        params: { userId },
        method: 'GET',
        query: null,
        body: null,
        // Assertion needed until all types are updated
      })
      .pipe(map(({ body }) => body));
  }

  getCurrentUser(): Observable<User> {
    return this.api
      .call<Cws.GetCurrentUser>({
        origin: environment.api.cws.endpoint,
        path: '/user/current',
        params: {},
        method: 'GET',
        query: null,
        body: null,
        // Assertion needed until all types are updated
      })
      .pipe(map(({ body }) => body));
  }

  createUser(
    user: Omit<Cws.CreateUserFromAdmin['body'], 'signupSource'>
  ): Observable<User> {
    return this.api
      .call<Cws.CreateUserFromAdmin>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users',
        params: {},
        method: 'POST',
        query: null,
        body: { ...user, signupSource: SignupSource.CRM },
        // Assertion needed until all types are updated
      })
      .pipe(map(({ body }) => body));
  }

  restartTrial(agencyId: string): Observable<RestartTrialResponse> {
    return this.api
      .call<RestartTrial>({
        origin: environment.api.cws.endpoint,
        path: '/agency/:agencyId/restart-trial',
        params: { agencyId },
        method: 'POST',
        query: null,
        body: {},
      })
      .pipe(map(({ body }) => body));
  }

  private httpGetClientsAgencies(): Observable<ClientsAgencies> {
    return combineLatest([this.getAgencies(), this.getClients()]).pipe(
      map(([agencies, clients]) => ({
        agencies,
        clients,
      })),
      shareReplay(1)
    );
  }

  private getUsersSearchParams({
    ids,
    role,
    search,
    pageIndex,
    pageSize,
    accessIds,
    includeDeleted,
  }: Partial<UserListQuery> = {}): Cws.GetUsersAdmin['query'] {
    const httpParams: Cws.GetUsersAdmin['query'] = {};

    if (ids?.length) {
      httpParams.ids = ids.join(',');
    }

    if (role) {
      httpParams.role = role;
    }

    if (search) {
      httpParams.search = search;
    }

    if (includeDeleted) {
      httpParams.include_deleted = includeDeleted;
    }

    if (typeof pageIndex !== 'undefined') {
      httpParams.page = pageIndex.toString();
    }

    if (typeof pageSize !== 'undefined') {
      httpParams.page_size = pageSize.toString();
    }

    const accessParams: Record<string, string[]> = {};
    if (accessIds?.length) {
      accessParams.access_ids = accessIds;
    }

    return { ...accessParams, ...httpParams };
  }
}
