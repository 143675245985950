import { Component } from '@angular/core';
import { BreadcrumbsService } from '@core/services/breadcrumbs.service';

import { ElementId } from '@storykit/constants';

import { FeatureFlagsListComponent } from './list/feature-flags-list.component';

@Component({
  selector: 'app-feature-flags-view',
  templateUrl: './feature-flags.component.html',
  standalone: true,
  imports: [FeatureFlagsListComponent],
  styleUrls: ['./feature-flags.component.scss'],
})
export class FeatureFlagsViewComponent {
  elementId = ElementId;

  constructor(private breadcrumbsService: BreadcrumbsService) {
    this.breadcrumbsService.set([
      { label: 'Feature flags', link: '/feature-flags' },
    ]);
  }
}
