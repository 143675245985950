<mat-accordion>
  @for (flag of (featureFlags$ | async)?.flags || []; track flag._id) {
    <mat-expansion-panel
      [disabled]="!flag.users.length && !flag.cohorts.length"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <app-icon
            [name]="flag.active ? 'check-circle' : 'x-circle'"
            weight="fill"
            class="icon-{{ flag.active ? 'positive' : 'negative' }}"
          ></app-icon>
          &nbsp;
          <a [routerLink]="['/feature-flags', flag._id]">{{ flag.key }}</a>
        </mat-panel-title>
        <mat-panel-description>
          {{ flag.description }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="panel-content">
        <ng-container
          *ngTemplateOutlet="userListTemplate; context: { users: flag.users }"
        ></ng-container>

        @if (flag.cohorts.length) {
          <div>
            <h3>Cohorts</h3>
            <ul style="list-style: none; margin: 0; padding: 0 0 0 15px">
              @for (cohort of flag.cohorts; track cohort) {
                <li>
                  <app-cohort-info [cohort]="cohort"></app-cohort-info>
                </li>
              }
            </ul>
          </div>
        }
      </div>
    </mat-expansion-panel>
  }
</mat-accordion>

<ng-template #userListTemplate let-users="users">
  @if (users.length) {
    <div>
      <h3>Users</h3>
      <ul style="list-style: none; margin: 0; padding: 0 0 0 15px">
        @for (user of users; track user.user.id || user.user.email?.text) {
          <li>
            <div>
              <app-icon
                [name]="user.active ? 'check-circle' : 'x-circle'"
                weight="fill"
                class="icon-{{ user.active ? 'positive' : 'negative' }}"
              ></app-icon>
              &nbsp;
              @if (user.user.id) {
                {{ user.user.id }}
              } @else {
                {{ user.user.email?.comparator }}
                {{ user.user.email?.text }}
              }
            </div>
          </li>
        }
      </ul>
    </div>
  }
</ng-template>

<br /><br />

<div class="u-width--full u-marginRxs">
  <mat-label class="o-form-label">New Feature Flag Key</mat-label>
  <mat-form-field style="width: 100%">
    <input
      type="text"
      matInput
      placeholder="Key"
      autocomplete="off"
      #keyInput
    />
  </mat-form-field>

  <button
    mat-raised-button
    color="primary"
    (click)="createFeatureFlag(keyInput.value)"
  >
    Create Feature Flag
  </button>
</div>
<br />
<br />
<div class="u-width--full u-marginRxs">
  <mat-label class="o-form-label">Check user</mat-label>

  <form [formGroup]="checkForm">
    <app-user-autocomplete-selectbox formControlName="id" style="width: 100%" />
  </form>
  <br />
  <button
    mat-raised-button
    color="primary"
    (click)="checkUser(checkForm.get('id')?.value || '')"
  >
    Check user feature flag reasons
  </button>

  @if (checks) {
    <br />
    <br />
    <table
      mat-table
      [dataSource]="checks || []"
      class="mat-elevation-z8"
      style="width: 100%"
    >
      <!-- Active Column -->
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef>Active</th>
        <td mat-cell *matCellDef="let check">
          <app-icon
            [name]="check[0] ? 'check-circle' : 'x-circle'"
            weight="fill"
            class="icon-{{ check[0] ? 'positive' : 'negative' }}"
          ></app-icon>
        </td>
      </ng-container>

      <!-- Flag Key Column -->
      <ng-container matColumnDef="flag">
        <th mat-header-cell *matHeaderCellDef>Flag</th>
        <td mat-cell *matCellDef="let check">{{ check[1] }}</td>
      </ng-container>

      <!-- Reason Column -->
      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef>Reason</th>
        <td mat-cell *matCellDef="let check">{{ check[2] }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['active', 'flag', 'reason']"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: ['active', 'flag', 'reason']"
      ></tr>
    </table>
  }
</div>
