import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom, map } from 'rxjs';

import { OperationStatus, longTaskParam } from '@storykit/constants';
import { Cws } from '@storykit/typings';
import {
  BaseDefinition,
  ExtractRouteParams,
  Operation,
} from '@storykit/typings/src/shared';

const DEFAULT_POLLING_INTERVAL = 2000;

// Removes `null` as a possible value for body, isn't relevant for our types
interface Response<T> extends HttpResponse<T> {
  readonly body: T;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private static readonly BODY_METHODS = ['PUT', 'POST', 'PATCH'];

  constructor(private http: HttpClient) {}

  /**
   * @example
   * ```typescript
   * this.api.call<Cws.GetUser>({
   *   origin: environment.api.cwsUrl,
   *   path: '/user/:userId',
   *   params: { userId },
   *   method: 'GET',
   *   query: {},
   *   body: {},
   * }).pipe(map(({ body }) => body));
   * ```
   */
  call<Definition extends BaseDefinition>(definition: {
    origin: string;
    path: Definition['path'];
    method: Definition['method'];
    params: ExtractRouteParams<Definition['path']>;
    query: Definition['query'];
    body: Definition['body'];
    headers?: Record<string, string>;
    longTask?: { enabled?: boolean; pollingInterval?: number };
  }): Observable<Response<Definition['response']>> {
    const path = this.replaceParams(definition.path, definition.params);
    const url = new URL(path, definition.origin);
    const withBody = ApiService.BODY_METHODS.includes(definition.method);
    const isLongTask = definition.longTask?.enabled;
    const options = {
      params: definition.query,
      observe: 'response',
      headers: definition.headers,
    };

    if (isLongTask) {
      url.searchParams.set(longTaskParam, 'true');
    }

    const method = (this.http as any)[definition.method.toLowerCase()].bind(
      this.http
    );
    const observable = method(
      url.toString(),
      withBody ? definition.body : options,
      withBody ? options : undefined
    );

    return isLongTask
      ? this.pollOperation(observable as Observable<Operation>, definition)
      : (observable as Observable<Response<Definition['response']>>);
  }

  pollOperation<Definition extends BaseDefinition>(
    observable: Observable<Operation>,
    definition: Parameters<ApiService['call']>[0]
  ): Observable<Response<Definition['response']>> {
    const timeout =
      definition.longTask?.pollingInterval || DEFAULT_POLLING_INTERVAL;

    return new Observable((observer) => {
      lastValueFrom(observable)
        .then((data) => {
          const { operationId } = data.body;

          const checkStatus = async () => {
            try {
              const data = await lastValueFrom(
                this.call<
                  Cws.GetOperation<Response<Definition['response']>['body']>
                >({
                  origin: definition.origin,
                  path: `/operation/:operationId`,
                  method: 'GET',
                  params: { operationId },
                  query: null,
                  body: {},
                  longTask: { enabled: false },
                }).pipe(map((res) => res.body))
              );
              if (data.status === OperationStatus.COMPLETED) {
                const response = new HttpResponse({
                  body: data.body,
                  headers: new HttpHeaders(data.headers),
                });

                observer.next(response);
                observer.complete();
              } else if (data.status === OperationStatus.NOT_FOUND) {
                observer.error(new Error('Operation not found'));
              } else {
                setTimeout(checkStatus, timeout);
              }
            } catch (err) {
              observer.error(err);
            }
          };

          setTimeout(checkStatus, timeout);
        })
        .catch((err: any) => observer.error(err));
    });
  }

  /**
   * Replaces params in a route string with real values
   *
   * @example
   * ```typescript
   * replaceParams('/users/:id', { id: '1' }) => '/users/1'
   * ```
   */
  private replaceParams(path: string, params: Record<string, string>): string {
    return path.replace(/\/:([^/]*)/g, (_, name) => `/${params[name]}`);
  }
}
