<mat-form-field
  class="user-autocomplete"
  style="width: 100%"
  subscriptSizing="dynamic"
  appearance="fill"
  color="primary"
>
  <input
    matInput
    [formControl]="searchControl"
    [matAutocomplete]="auto"
    placeholder="Start typing to search..."
  />
  @if (searchControl.value) {
    {{ getUserInfo(searchControl.value) }}
  }
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="onUserSelected($event)"
  >
    @for (user of filteredUsers$ | async; track user._id) {
      <mat-option [value]="user._id">
        <small>{{ user._id }}</small>
        <br />
        {{ user.firstName }} {{ user.lastName }} ({{ user.email }})
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
