import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterLink } from '@angular/router';

import { FeatureFlagCohort } from '@storykit/typings/src/cws/feature-flag';
import { IconComponent } from '@storykit/ui-components';

@Component({
  selector: 'app-cohort-info',
  standalone: true,
  imports: [IconComponent, RouterLink, MatExpansionModule],
  templateUrl: './cohort-info.component.html',
  styleUrl: './cohort-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CohortInfoComponent {
  cohort = input.required<FeatureFlagCohort>();
}
